export const timezonesOptions = [
    {
        label: "Easter Island Summer Time",
        value: "Pacific/Easter",
    },
    {
        label: "Chile Summer Time",
        value: "America/Punta_Arenas",
    },
    {
        label: "Chile Standard Time",
        value: "America/Santiago",
    },
    {
        label: "US/Pacific",
        value: "US/Pacific",
    },
    {
        label: "US/Central",
        value: "US/Central",
    },
    {
        label: "US/Mountain",
        value: "US/Mountain",
    },
    {
        label: "US/Eastern",
        value: "US/Eastern",
    },
];

export const notificationSettingOptions = [
    {
        label: "Received",
        value: "order/confirm_notify",
    },
    {
        label: "Package And Ready",
        value: "order/ready_notify",
    },
    {
        label: "Driver On Route",
        value: "order/on_route_notify",
    },
    {
        label: "Complete",
        value: "order/completed_notify",
    },
    {
        label: "Declined",
        value: "order/declined_notify",
    },
    {
        label: "Contact Business Owner",
        value: "contact/business_owner",
    },
];

export const notificationSettingTypeOptions = [
    {
        label: "Email",
        value: "EMAIL",
    },
    // {
    //     label: "SMS",
    //     value: "SMS"
    // },
];

export const snapShotOptions = [
    {
        label: "Today",
        value: 0,
    },

    {
        label: "Yesterday",
        value: 1,
    },

    {
        label: "This week",
        value: 2,
    },

    {
        label: "Last 7 days",
        value: 3,
    },

    {
        label: "Last 14 days",
        value: 4,
    },

    {
        label: "Last 30 days",
        value: 5,
    },

    {
        label: "This month",
        value: 6,
    },

    {
        label: "Last month",
        value: 7,
    },
];

export const usStates = [
    {
        label: "Alabama",
        value: "AL",
    },
    {
        label: "Alaska",
        value: "AK",
    },
    {
        label: "American Samoa",
        value: "AS",
    },
    {
        label: "Arizona",
        value: "AZ",
    },
    {
        label: "Arkansas",
        value: "AR",
    },
    {
        label: "California",
        value: "CA",
    },
    {
        label: "Colorado",
        value: "CO",
    },
    {
        label: "Connecticut",
        value: "CT",
    },
    {
        label: "Delaware",
        value: "DE",
    },
    {
        label: "District Of Columbia",
        value: "DC",
    },
    {
        label: "Federated States Of Micronesia",
        value: "FM",
    },
    {
        label: "Florida",
        value: "FL",
    },
    {
        label: "Georgia",
        value: "GA",
    },
    {
        label: "Guam",
        value: "GU",
    },
    {
        label: "Hawaii",
        value: "HI",
    },
    {
        label: "Idaho",
        value: "ID",
    },
    {
        label: "Illinois",
        value: "IL",
    },
    {
        label: "Indiana",
        value: "IN",
    },
    {
        label: "Iowa",
        value: "IA",
    },
    {
        label: "Kansas",
        value: "KS",
    },
    {
        label: "Kentucky",
        value: "KY",
    },
    {
        label: "Louisiana",
        value: "LA",
    },
    {
        label: "Maine",
        value: "ME",
    },
    {
        label: "Marshall Islands",
        value: "MH",
    },
    {
        label: "Maryland",
        value: "MD",
    },
    {
        label: "Massachusetts",
        value: "MA",
    },
    {
        label: "Michigan",
        value: "MI",
    },
    {
        label: "Minnesota",
        value: "MN",
    },
    {
        label: "Mississippi",
        value: "MS",
    },
    {
        label: "Missouri",
        value: "MO",
    },
    {
        label: "Montana",
        value: "MT",
    },
    {
        label: "Nebraska",
        value: "NE",
    },
    {
        label: "Nevada",
        value: "NV",
    },
    {
        label: "New Hampshire",
        value: "NH",
    },
    {
        label: "New Jersey",
        value: "NJ",
    },
    {
        label: "New Mexico",
        value: "NM",
    },
    {
        label: "New York",
        value: "NY",
    },
    {
        label: "North Carolina",
        value: "NC",
    },
    {
        label: "North Dakota",
        value: "ND",
    },
    {
        label: "Northern Mariana Islands",
        value: "MP",
    },
    {
        label: "Ohio",
        value: "OH",
    },
    {
        label: "Oklahoma",
        value: "OK",
    },
    {
        label: "Oregon",
        value: "OR",
    },
    {
        label: "Palau",
        value: "PW",
    },
    {
        label: "Pennsylvania",
        value: "PA",
    },
    {
        label: "Puerto Rico",
        value: "PR",
    },
    {
        label: "Rhode Island",
        value: "RI",
    },
    {
        label: "South Carolina",
        value: "SC",
    },
    {
        label: "South Dakota",
        value: "SD",
    },
    {
        label: "Tennessee",
        value: "TN",
    },
    {
        label: "Texas",
        value: "TX",
    },
    {
        label: "Utah",
        value: "UT",
    },
    {
        label: "Vermont",
        value: "VT",
    },
    {
        label: "Virgin Islands",
        value: "VI",
    },
    {
        label: "Virginia",
        value: "VA",
    },
    {
        label: "Washington",
        value: "WA",
    },
    {
        label: "West Virginia",
        value: "WV",
    },
    {
        label: "Wisconsin",
        value: "WI",
    },
    {
        label: "Wyoming",
        value: "WY",
    },
];

export const orderStatus = [
    {
        label: "ABANDONED_CART",
        value: "ABANDONED_CART",
    },

    {
        label: "PENDING",
        value: "PENDING",
    },

    {
        label: "PROCESSING",
        value: "PROCESSING",
    },

    {
        label: "SHIPPED",
        value: "SHIPPED",
    },

    {
        label: "DECLINED",
        value: "DECLINED",
    },

    {
        label: "VOID",
        value: "VOID",
    },

    {
        label: "REFUNDED",
        value: "REFUNDED",
    },
];

export const currencyOptions = [
    {
        label: "USD",
        value: "USD",
    },
    {
        label: "CLP",
        value: "CLP",
    },
];

export const onfleetAsignModeOptions = [
    {
        label: "Load",
        value: "LOAD",
    },
    {
        label: "Distance",
        value: "DISTANCE",
    },
];

export const taxTypeOptions = [
    {
        value: "PERCENTAGE",
        label: "Percentage",
    },
    {
        value: "FLAT_RATE",
        label: "Flat Rate",
    },
];

export const discountType = [
    // {
    //     value: "AMOUNT_OFF_PRODUCT_PRICE",
    //     label: "Amount off Product Price"
    // },
    {
        value: "AMOUNT_OFF_ORDER",
        label: "Amount Off Order",
    },
    // {
    //     value: "QUANTITY_DISCOUNT",
    //     label: "Quantity Discount"
    // },
    {
        value: "PRODUCT_DISCOUNT",
        label: "Product Discount",
    },
    {
        value: "FREE_DELIVERY_DISCOUNT",
        label: "Free Delivery Discount",
    },
    // {
    //     value: "BUY_ONE_GET_ONE_FREE",
    //     label: "Buy One Get One Free"
    // },
    // {
    //     value: "BUY_ONE_GET_ONE_AMOUNT_OFF",
    //     label: "Buy One Get One Amount Off"
    // },
    // {
    //     value: "BUY_ONE_GET_ONE_PERCENT_OFF",
    //     label: "Buy One Get One Percent Off"
    // },
    // {
    //     value: "BUY_ONE_GET_ANOTHER_FREE",
    //     label: "Buy One Get Another Free"
    // },
    // {
    //     value: "BUY_ONE_GET_ANOTHER_AMOUNT_OFF",
    //     label: "Buy One Get Another Amount Off"
    // },
    // {
    //     value: "BUY_ONE_GET_ANOTHER_PERCENT_OFF",
    //     label: "Buy One Get Another Percent Off"
    // },
    // {
    //     value: "FREE_PRODUCT",
    //     label: "Free Product"
    // }
];

export const broadcasttype = [
    {
        value: "HEADER_BAR_HOME_PAGE",
        label: "Header Bar Home Page",
    },
    {
        value: "HEADER_BAR_ALL_PAGE",
        label: "Header Bar All Page",
    },
    {
        value: "EMAIL_POPUP",
        label: "Email Popup",
    },
];

export const incrementRateType = [
    {
        value: "AMOUNT",
        label: "Amount",
    },
    {
        value: "PERCENTAGE",
        label: "Percentage",
    },
];

export const discountRateType = [
    {
        value: "AMOUNT",
        label: "Amount",
    },
    {
        value: "PERCENTAGE",
        label: "Percentage",
    },
];

export const discountApplyType = [
    {
        value: "AUTOMATICALLY",
        label: "Automatically",
    },
    {
        value: "PROMOTION_CODE",
        label: "Promotion Code",
    },
];

export const discountQuantityApply = [
    {
        value: "ALL_PRODUCTS",
        label: "All Products",
    },
    {
        value: "EACH_PRODUCT",
        label: "Each Product",
    },
];

export const roleOptions = [
    {
        value: "SUPER_ADMIN",
        label: "Super Admin",
    },
    {
        value: "ADMIN",
        label: "Admin",
    },
    {
        value: "SALLER",
        label: "Seller",
    },
];

/**
 * PAGES
 */

// Make sure your are including parenthesis.
// We need it to select and wrap matched
// expressions into <mark> tags.
export const PAGE_VARIABLE_START = "{{";
export const PAGE_VARIABLE_END = "}}";
export const PAGE_VARIABLES_REGEXP = new RegExp(
    `(${PAGE_VARIABLE_START}[\\w\\.]+${PAGE_VARIABLE_END})`,
    "g",
);

export const PageVariables = {
    BRAND: {
        id: "Unique field that identifies the brand",
        name: "The field of identification or reference of the brand",
        phone_number:
            "Field that has the contact telephone number of the brand",
        "asset.thumbnail": "Address of preview image with reduced file size",
        "asset.image": "Address of the brand image",
        "asset.image_large_size": "Brand image direction for large device",
        "asset.image_medium_size": "Brand image direction for medium device",
        "asset.image_extra_large_size":
            "Brand image direction for extra large device",
        website: "Official brand websites",
        vendors: "Names of the providers that offer their services",
        enabled:
            "This field enables the brand for its use or visibility in the system",
        created_at: "Indicates the date of creation of the brand in the system",
        updated_at:
            "Indicates the date of the last update of the brand information",
    },
    CATEGORY: {
        id: "Unique field that identifies each category",
        name: "The field of identification or reference of the category",
        enabled:
            "This field enables the category for its use or visibility in the system",
        is_cannabis: "Defines if the category is cannabis",
        created_at:
            "Indicates the date of creation of the category in the system",
        updated_at:
            "Indicates the date of the last update of the category information",
        description:
            "Detailed explanation of the characteristics of the category",
        short_description: "Synthesis of the description of the category",
        priority: "Priority order within categories",
        unit_type: "Lorem ipsum unit_type sit",
        cannabis_type: "Lorem ipsum cannabis_type sit",
        wm_category: "Lorem ipsum wm_category sit",
        low_threshold: "Lorem ipsum low_threshold sit",
    },
    PRODUCT: {
        id: "Unique field that identifies each product",
        sku: "Stock keeping units are assigned to products to track internal stock levels",
        name: "The field of identification or reference of the product",
        enabled:
            "This field enables the category for its use or visibility in the system",
        description:
            "Detailed explanation of the characteristics of the product",
        category: "Category to which the product belongs",
        category_id: "Unique field that identifies each category",
        cannabis_type: "Lorem ipsum cannabis_type sit",
        product_type: "Lorem ipsum product_type sit",
        product_sale_type: "Lorem ipsum product_sale_type sit",
        flower_type: "Flower type with which they made the product",
        in_stock: "Defines if the product is available",
        created_at:
            "Indicates the date of creation of the product in the system",
        updated_at:
            "Indicates the date of the last update of the product information",
        qty: "Amount",
        unit_price: "Price for each of the products",
        brand_name: "Name of the brand that sells the product",
    },
};

export const templateOptions = [
    { value: "HOME", label: "Home" },
    { value: "SPECIALS", label: "Specials" },
    { value: "CONTACT", label: "Contact" },
    { value: "ABOUT", label: "About Us" },
    { value: "TEXT", label: "Text" },
    { value: "CATALOG", label: "Catalog" },
    { value: "BLOG", label: "Blog" },
    { value: "ARTICLE", label: "Article" },
    { value: "BRAND", label: "Brand" },
    { value: "STATE", label: "State" },
    { value: "CITY", label: "City" },
    { value: "LOCATION", label: "Location" },
    { value: "BRANDS_LIST", label: "Brands List" },
    { value: "PRODUCT", label: "Product" },
    { value: "MENU", label: "Menu" },
];

export const targetType = [
    { value: "_self", label: "Self" },
    { value: "_blank", label: "Blank" },
    { value: "_parent", label: "Parent" },
    { value: "_top", label: "Top" },
];

export const typeFileUpload = [
    {
        value: "videos",
        label: "Product Video",
    },
    {
        value: "thumbnails",
        label: "Thumbnails",
    },
    {
        value: "images",
        label: "Images",
    },
];

export const optionOrders = [
    {
        value: 0,
        label: "Actions",
    },
    {
        value: 1,
        label: "Print Invoice",
    },
    {
        value: 2,
        label: "Print Packing Slips",
    },
];

export const data_test_orders = [
    {
        id: "qwer",
        key: "qw12er34qw",
        status: "SHIPPED",
        created_at: "2020-02-01T15:25:06+00:00",
        update_at: "2020-02-01T15:25:06+00:00",
        email: "test1@gmail.com",
        phone_number: "12345678",
        billing_address: {
            address1: "billing address1",
            address2: "billing address2",
            city: "billing city",
            company: "billing company",
            country: "billing country",
            first_name: "billing first_name",
            last_name: "billing last_name",
            phone: "billing phone",
            province: "billing province",
            zip: "billing zip",
            name: "billing name",
            province_code: "billing province_code",
            country_code: "billing country_code",
            latitude: 11.11,
            longitude: 11.11,
        },
        shipping_address: {
            address1: "shipping address1",
            address2: "shipping address2",
            city: "shipping city",
            company: "shipping company",
            country: "shipping country",
            first_name: "shipping first_name",
            last_name: "shipping last_name",
            phone: "shipping phone1",
            province: "shipping province",
            zip: "shipping zip",
            name: "shipping name",
            province_code: "shipping province_code",
            country_code: "shipping country_code",
            latitude: 12.12,
            longitude: 12.12,
        },
        browser_ip: "browser_ip",
        discount_items: [
            {
                id: "discount1",
            },
            {
                id: "discount2",
            },
            {
                id: "discount3",
            },
        ],
        line_items: [
            {
                id: "id",
                name: "name",
                quantity: 60,
                price: 61,
                weight: 62,
                thumbnail: "thumbnail",
            },
            {
                id: "id2",
                name: "name2",
                quantity: 60,
                price: 61,
                weight: 62,
                thumbnail: "thumbnail2",
            },
        ],
        note: "note",
        order_number: 40,
        invoice_date: "2020-02-01T15:25:06+00:00",
        source_name: "source_name",
        payment_details: {
            transaction_id: "transaction_id",
            avs_result_code: "avs_result_code",
            credit_card_bin: "credit_card_bin",
            cvv_result_code: "cvv_result_code",
            credit_card_number: "credit_card_number",
            credit_card_company: "credit_card_company",
        },
        buyer_accepts_marketing: true,
        user_id: "1234",
        shipment_detail: [
            {
                id: "shipment_detail1",
            },
            {
                id: "shipment_detail2",
            },
            {
                id: "shipment_detail3",
            },
        ],
        subtotal_price: 20,
        total_discounts: 21,
        total_line_items_price: 22,
        total_price: 23,
        total_tax: 24,
        total_shipping: 25,
    },
    {
        id: "q26gwer",
        key: "qw12er34qw",
        status: "SHIPPED2",
        created_at: "2020-02-01T15:25:06+00:00",
        update_at: "2020-02-01T15:25:06+00:00",
        email: "test1@gmail.com",
        phone_number: "12345678",
        billing_address: {
            address1: "billing address1",
            address2: "billing address2",
            city: "billing city",
            company: "billing company",
            country: "billing country",
            first_name: "billing first_name",
            last_name: "billing last_name",
            phone: "billing phone",
            province: "billing province",
            zip: "billing zip",
            name: "billing name",
            province_code: "billing province_code",
            country_code: "billing country_code",
            latitude: 11.11,
            longitude: 11.11,
        },
        shipping_address: {
            address1: "shipping address1",
            address2: "shipping address2",
            city: "shipping city",
            company: "shipping company",
            country: "shipping country",
            first_name: "shipping first_name",
            last_name: "shipping last_name",
            phone: "shipping phone2",
            province: "shipping province",
            zip: "shipping zip",
            name: "shipping name",
            province_code: "shipping province_code",
            country_code: "shipping country_code",
            latitude: 12.12,
            longitude: 12.12,
        },
        browser_ip: "browser_ip",
        discount_items: [
            {
                id: "discount1",
            },
            {
                id: "discount2",
            },
            {
                id: "discount3",
            },
        ],
        line_items: [
            {
                id: "id",
                name: "name",
                quantity: 60,
                price: 61,
                weight: 62,
                thumbnail: "thumbnail",
            },
            {
                id: "id2",
                name: "name2",
                quantity: 60,
                price: 61,
                weight: 62,
                thumbnail: "thumbnail2",
            },
        ],
        note: "note",
        order_number: 40,
        invoice_date: "2020-02-01T15:25:06+00:00",
        source_name: "source_name",
        payment_details: {
            transaction_id: "transaction_id",
            avs_result_code: "avs_result_code",
            credit_card_bin: "credit_card_bin",
            cvv_result_code: "cvv_result_code",
            credit_card_number: "credit_card_number",
            credit_card_company: "credit_card_company",
        },
        buyer_accepts_marketing: true,
        user_id: "1234",
        shipment_detail: [
            {
                id: "shipment_detail1",
            },
            {
                id: "shipment_detail2",
            },
            {
                id: "shipment_detail3",
            },
        ],
        subtotal_price: 20,
        total_discounts: 21,
        total_line_items_price: 22,
        total_price: 23,
        total_tax: 24,
        total_shipping: 25,
    },
    {
        id: "qwerwer",
        key: "qw12er34qw",
        status: "SHIPPED3",
        created_at: "2020-02-01T15:25:06+00:00",
        update_at: "2020-02-01T15:25:06+00:00",
        email: "test1@gmail.com",
        phone_number: "12345678",
        billing_address: {
            address1: "billing address1",
            address2: "billing address2",
            city: "billing city",
            company: "billing company",
            country: "billing country",
            first_name: "billing first_name",
            last_name: "billing last_name",
            phone: "billing phone",
            province: "billing province",
            zip: "billing zip",
            name: "billing name",
            province_code: "billing province_code",
            country_code: "billing country_code",
            latitude: 11.11,
            longitude: 11.11,
        },
        shipping_address: {
            address1: "shipping address1",
            address2: "shipping address2",
            city: "shipping city",
            company: "shipping company",
            country: "shipping country",
            first_name: "shipping first_name",
            last_name: "shipping last_name",
            phone: "shipping phone3",
            province: "shipping province",
            zip: "shipping zip",
            name: "shipping name",
            province_code: "shipping province_code",
            country_code: "shipping country_code",
            latitude: 12.12,
            longitude: 12.12,
        },
        browser_ip: "browser_ip",
        discount_items: [
            {
                id: "discount1",
            },
            {
                id: "discount2",
            },
            {
                id: "discount3",
            },
        ],
        line_items: [
            {
                id: "id",
                name: "name",
                quantity: 60,
                price: 61,
                weight: 62,
                thumbnail: "thumbnail",
            },
            {
                id: "id2",
                name: "name2",
                quantity: 60,
                price: 61,
                weight: 62,
                thumbnail: "thumbnail2",
            },
        ],
        note: "note",
        order_number: 40,
        invoice_date: "2020-02-01T15:25:06+00:00",
        source_name: "source_name",
        payment_details: {
            transaction_id: "transaction_id",
            avs_result_code: "avs_result_code",
            credit_card_bin: "credit_card_bin",
            cvv_result_code: "cvv_result_code",
            credit_card_number: "credit_card_number",
            credit_card_company: "credit_card_company",
        },
        buyer_accepts_marketing: true,
        user_id: "1234",
        shipment_detail: [
            {
                id: "shipment_detail1",
            },
            {
                id: "shipment_detail2",
            },
            {
                id: "shipment_detail3",
            },
        ],
        subtotal_price: 20,
        total_discounts: 21,
        total_line_items_price: 22,
        total_price: 23,
        total_tax: 24,
        total_shipping: 25,
    },
];

export const headers_orders = [
    { label: "Invoice #", key: "order_number" },
    { label: "Customer Name", key: "billing_address_first_name" },
    { label: "Purchase Date", key: "created_at" },
    { label: "Subtotal", key: "subtotal_price" },
    { label: "Tax", key: "total_tax" },
    { label: "Delivery", key: "shipping_address_address1" },
    { label: "Grand Total", key: "total" },
    { label: "Order status", key: "status" },
];

export const headers_products = [
    { label: "Id", key: "id" },
    { label: "Title", key: "title" },
    { label: "Description", key: "description" },
    { label: "Price", key: "price" },
    { label: "Status", key: "status" },
];

export const headers_customers = [
    { label: "Email", key: "email" },
    { label: "Customer Name", key: "first_name" },
    { label: "Created At", key: "created_at" },
    { label: "Update At", key: "update_at" },
];

export const headers_users = [
    { label: "No.", key: "id" },
    { label: "Email", key: "email" },
    { label: "User Name", key: "first_name" },
    { label: "Active", key: "active" },
    { label: "Role", key: "role" },
    { label: "Status", key: "status" },
    { label: "Created At", key: "created_at" },
    { label: "Update At", key: "update_at" },
];

export const applyType = [
    {
        value: "ORDER",
        label: "Order",
    },
    {
        value: "EACH_ITEM",
        label: "Each Item",
    },
];

export const allRegionStore = [
    {
        value: "ARIC",
        label: "Arica",
    },
    {
        value: "Camarones",
        label: "Camarones",
    },
    {
        value: "Putre",
        label: "Putre",
    },
    {
        value: "General Lagos",
        label: "General Lagos",
    },
    {
        value: "IQUI",
        label: "Iquique",
    },
    {
        value: "AHOS",
        label: "Alto Hospicio",
    },
    {
        value: "POZO",
        label: "Pozo Almonte",
    },
    {
        value: "Camiña",
        label: "Camiña",
    },
    {
        value: "Colchane",
        label: "Colchane",
    },
    {
        value: "Huara",
        label: "Huara",
    },
    {
        value: "Pica",
        label: "Pica",
    },
    {
        value: "ANTO",
        label: "Antofagasta",
    },
    {
        value: "MEJI",
        label: "Mejillones",
    },
    {
        value: "SGOR",
        label: "Sierra Gorda",
    },
    {
        value: "TALT",
        label: "Taltal",
    },
    {
        value: "CALA",
        label: "Calama",
    },
    {
        value: "RTOM",
        label: "Ollagüe",
    },
    {
        value: "SPAT",
        label: "San Pedro de Atacama",
    },
    {
        value: "TOCO",
        label: "Tocopilla",
    },
    {
        value: "MARI",
        label: "María Elena",
    },
    {
        value: "PAIP",
        label: "Copiapó",
    },
    {
        value: "CALD",
        label: "Caldera",
    },
    {
        value: "TAMA",
        label: "Tierra Amarilla",
    },
    {
        value: "CHAN",
        label: "Chañaral",
    },
    {
        value: "ELSA",
        label: "Diego de Almagro",
    },
    {
        value: "VALL",
        label: "Vallenar",
    },
    {
        value: "Alto del Carmen",
        label: "Alto del Carmen",
    },
    {
        value: "FREI",
        label: "Freirina",
    },
    {
        value: "HUAS",
        label: "Huasco",
    },
    {
        value: "LASE",
        label: "La Serena",
    },
    {
        value: "COQU",
        label: "Coquimbo",
    },
    {
        value: "ANDA",
        label: "Andacollo",
    },
    {
        value: "La Higuera",
        label: "La Higuera",
    },
    {
        value: "Paiguano",
        label: "Paiguano",
    },
    {
        value: "VICU",
        label: "Vicuña",
    },
    {
        value: "ILLA",
        label: "Illapel",
    },
    {
        value: "Canela",
        label: "Canela",
    },
    {
        value: "LVIL",
        label: "Los Vilos",
    },
    {
        value: "SALA",
        label: "Salamanca",
    },
    {
        value: "OVAL",
        label: "Ovalle",
    },
    {
        value: "COMB",
        label: "Combarbalá",
    },
    {
        value: "MOPA",
        label: "Monte Patria",
    },
    {
        value: "PUNI",
        label: "Punitaqui",
    },
    {
        value: "Río Hurtado",
        label: "Río Hurtado",
    },
    {
        value: "VALP",
        label: "Valparaíso",
    },
    {
        value: "CASA",
        label: "Casablanca",
    },
    {
        value: "CCON",
        label: "Concón",
    },
    {
        value: "Juan Fernández",
        label: "Juan Fernández",
    },
    {
        value: "PUCH",
        label: "Puchuncaví",
    },
    {
        value: "QUIN",
        label: "Quintero",
    },
    {
        value: "VINA",
        label: "Viña del Mar",
    },
    {
        value: "Isla de Pascua",
        label: "Isla de Pascua",
    },
    {
        value: "LAND",
        label: "Los Andes",
    },
    {
        value: "Calle Larga",
        label: "Calle Larga",
    },
    {
        value: "Riconada",
        label: "Riconada",
    },
    {
        value: "San Esteban",
        label: "San Esteban",
    },
    {
        value: "LALI",
        label: "La Ligua",
    },
    {
        value: "Cabildo",
        label: "Cabildo",
    },
    {
        value: "Papudo",
        label: "Papudo",
    },
    {
        value: "Petorca",
        label: "Petorca",
    },
    {
        value: "Zapallar",
        label: "Zapallar",
    },
    {
        value: "QLTA",
        label: "Quillota",
    },
    {
        value: "LACA",
        label: "Calera",
    },
    {
        value: "HIJU",
        label: "Hijuelas",
    },
    {
        value: "LACR",
        label: "La Cruz",
    },
    {
        value: "NOGA",
        label: "Nogales",
    },
    {
        value: "SANT",
        label: "San Antonio",
    },
    {
        value: "ALGA",
        label: "Algarrobo",
    },
    {
        value: "SSEB",
        label: "Cartagena",
    },
    {
        value: "INEG",
        label: "El Quisco",
    },
    {
        value: "LCRU",
        label: "El Tabo",
    },
    {
        value: "SDGO",
        label: "Santo Domingo",
    },
    {
        value: "SFEL",
        label: "San Felipe",
    },
    {
        value: "Catemu",
        label: "Catemu",
    },
    {
        value: "LLAY",
        label: "Llaillai",
    },
    {
        value: "Panquehue",
        label: "Panquehue",
    },
    {
        value: "Putaendo",
        label: "Putaendo",
    },
    {
        value: "SMAR",
        label: "Santa María",
    },
    {
        value: "QUIL",
        label: "Quilpué",
    },
    {
        value: "SFLI",
        label: "Limache",
    },
    {
        value: "OLMU",
        label: "Olmué",
    },
    {
        value: "VALE",
        label: "Villa Alemana",
    },
    {
        value: "RANC",
        label: "Rancagua",
    },
    {
        value: "CODE",
        label: "Codegua",
    },
    {
        value: "COIN",
        label: "Coinco",
    },
    {
        value: "COLT",
        label: "Coltauco",
    },
    {
        value: "LOMI",
        label: "Doñihue",
    },
    {
        value: "GRAN",
        label: "Graneros",
    },
    {
        value: "LCAB",
        label: "Las Cabras",
    },
    {
        value: "MACH",
        label: "Machalí",
    },
    {
        value: "PELE",
        label: "Malloa",
    },
    {
        value: "SFRA",
        label: "Mostazal",
    },
    {
        value: "OLIV",
        label: "Olivar",
    },
    {
        value: "PEUM",
        label: "Peumo",
    },
    {
        value: "PICD",
        label: "Pichidegua",
    },
    {
        value: "QTIL",
        label: "Quinta de Tilcoco",
    },
    {
        value: "ROSA",
        label: "Rengo",
    },
    {
        value: "REQU",
        label: "Requínoa",
    },
    {
        value: "SVIC",
        label: "San Vicente",
    },
    {
        value: "PICH",
        label: "Pichilemu",
    },
    {
        value: "La Estrella",
        label: "La Estrella",
    },
    {
        value: "LCHE",
        label: "Litueche",
    },
    {
        value: "MARC",
        label: "Marchihue",
    },
    {
        value: "Navidad",
        label: "Navidad",
    },
    {
        value: "Paredones",
        label: "Paredones",
    },
    {
        value: "SFER",
        label: "San Fernando",
    },
    {
        value: "CHEP",
        label: "Chépica",
    },
    {
        value: "CHIM",
        label: "Chimbarongo",
    },
    {
        value: "LOLO",
        label: "Lolol",
    },
    {
        value: "NANC",
        label: "Nancagua",
    },
    {
        value: "PALM",
        label: "Palmilla",
    },
    {
        value: "PERA",
        label: "Peralillo",
    },
    {
        value: "Placilla",
        label: "Placilla",
    },
    {
        value: "Pumanque",
        label: "Pumanque",
    },
    {
        value: "SCRU",
        label: "Santa Cruz",
    },
    {
        value: "TALC",
        label: "Talca",
    },
    {
        value: "CONS",
        label: "Constitución",
    },
    {
        value: "Curepto",
        label: "Curepto",
    },
    {
        value: "Empedrado",
        label: "Empedrado",
    },
    {
        value: "Maule",
        label: "Maule",
    },
    {
        value: "Pelarco",
        label: "Pelarco",
    },
    {
        value: "Pencahue",
        label: "Pencahue",
    },
    {
        value: "Río Claro",
        label: "Río Claro",
    },
    {
        value: "SCLE",
        label: "San Clemente",
    },
    {
        value: "San Rafael",
        label: "San Rafael",
    },
    {
        value: "CAUQ",
        label: "Cauquenes",
    },
    {
        value: "Chanco",
        label: "Chanco",
    },
    {
        value: "Pelluhue",
        label: "Pelluhue",
    },
    {
        value: "CURI",
        label: "Curicó",
    },
    {
        value: "HUAL",
        label: "Hualañé",
    },
    {
        value: "Licantén",
        label: "Licantén",
    },
    {
        value: "MOLI",
        label: "Molina",
    },
    {
        value: "Rauco",
        label: "Rauco",
    },
    {
        value: "ROME",
        label: "Romeral",
    },
    {
        value: "Sagrada Familia",
        label: "Sagrada Familia",
    },
    {
        value: "TENO",
        label: "Teno",
    },
    {
        value: "Vichuquén",
        label: "Vichuquén",
    },
    {
        value: "LINA",
        label: "Linares",
    },
    {
        value: "COLB",
        label: "Colbún",
    },
    {
        value: "LONG",
        label: "Longaví",
    },
    {
        value: "PARR",
        label: "Parral",
    },
    {
        value: "Retiro",
        label: "Retiro",
    },
    {
        value: "SJAV",
        label: "San Javier",
    },
    {
        value: "VALG",
        label: "Villa Alegre",
    },
    {
        value: "Yerbas Buenas",
        label: "Yerbas Buenas",
    },
    {
        value: "CONC",
        label: "Concepción",
    },
    {
        value: "CORO",
        label: "Coronel",
    },
    {
        value: "CHIG",
        label: "Chiguayante",
    },
    {
        value: "Florida",
        label: "Florida",
    },
    {
        value: "HAQI",
        label: "Hualqui",
    },
    {
        value: "LOTA",
        label: "Lota",
    },
    {
        value: "PENC",
        label: "Penco",
    },
    {
        value: "SPED",
        label: "San Pedro de la Paz",
    },
    {
        value: "Santa Juana",
        label: "Santa Juana",
    },
    {
        value: "THNO",
        label: "Talcahuano",
    },
    {
        value: "TOME",
        label: "Tomé",
    },
    {
        value: "HPEN",
        label: "Hualpén",
    },
    {
        value: "LEBU",
        label: "Lebu",
    },
    {
        value: "ARAU",
        label: "Arauco",
    },
    {
        value: "CANE",
        label: "Cañete",
    },
    {
        value: "Contulmo",
        label: "Contulmo",
    },
    {
        value: "CURA",
        label: "Curanilahue",
    },
    {
        value: "ALAM",
        label: "Los Álamos",
    },
    {
        value: "Tirúa",
        label: "Tirúa",
    },
    {
        value: "LANG",
        label: "Los Ángeles",
    },
    {
        value: "Antuco",
        label: "Antuco",
    },
    {
        value: "CABR",
        label: "Cabrero",
    },
    {
        value: "LAJA",
        label: "Laja",
    },
    {
        value: "MULC",
        label: "Mulchén",
    },
    {
        value: "NACI",
        label: "Nacimiento",
    },
    {
        value: "NEGR",
        label: "Negrete",
    },
    {
        value: "Quilaco",
        label: "Quilaco",
    },
    {
        value: "Quilleco",
        label: "Quilleco",
    },
    {
        value: "SANR",
        label: "San Rosendo",
    },
    {
        value: "SBAR",
        label: "Santa Bárbara",
    },
    {
        value: "TUCA",
        label: "Tucapel",
    },
    {
        value: "YUMB",
        label: "Yumbel",
    },
    {
        value: "Alto Biobío",
        label: "Alto Biobío",
    },
    {
        value: "NALD",
        label: "Chillán",
    },
    {
        value: "BULN",
        label: "Bulnes",
    },
    {
        value: "Cobquecura",
        label: "Cobquecura",
    },
    {
        value: "COEL",
        label: "Coelemu",
    },
    {
        value: "COIC",
        label: "Coihueco",
    },
    {
        value: "CHVJ",
        label: "Chillán Viejo",
    },
    {
        value: "ECAR",
        label: "El Carmen",
    },
    {
        value: "Ninhue",
        label: "Ninhue",
    },
    {
        value: "Ñiquén",
        label: "Ñiquén",
    },
    {
        value: "PEMU",
        label: "Pemuco",
    },
    {
        value: "PINT",
        label: "Pinto",
    },
    {
        value: "Portezuelo",
        label: "Portezuelo",
    },
    {
        value: "QULL",
        label: "Quillón",
    },
    {
        value: "QUIR",
        label: "Quirihue",
    },
    {
        value: "Ránquil",
        label: "Ránquil",
    },
    {
        value: "SCAR",
        label: "San Carlos",
    },
    {
        value: "San Fabián",
        label: "San Fabián",
    },
    {
        value: "SIGN",
        label: "San Ignacio",
    },
    {
        value: "San Nicolás",
        label: "San Nicolás",
    },
    {
        value: "Treguaco",
        label: "Treguaco",
    },
    {
        value: "YUNG",
        label: "Yungay",
    },
    {
        value: "TEMU",
        label: "Temuco",
    },
    {
        value: "CARA",
        label: "Carahue",
    },
    {
        value: "Cunco",
        label: "Cunco",
    },
    {
        value: "Curarrehue",
        label: "Curarrehue",
    },
    {
        value: "FRER",
        label: "Freire",
    },
    {
        value: "Galvarin",
        label: "Galvarin",
    },
    {
        value: "GORB",
        label: "Gorbea",
    },
    {
        value: "LAUT",
        label: "Lautaro",
    },
    {
        value: "LONC",
        label: "Loncoche",
    },
    {
        value: "Melipeuco",
        label: "Melipeuco",
    },
    {
        value: "NVAI",
        label: "Nueva Imperial",
    },
    {
        value: "QUEP",
        label: "Padre las Casas",
    },
    {
        value: "Perquenco",
        label: "Perquenco",
    },
    {
        value: "PITR",
        label: "Pitrufquén",
    },
    {
        value: "PUCO",
        label: "Pucón",
    },
    {
        value: "Saavedra",
        label: "Saavedra",
    },
    {
        value: "Teodoro Schmid",
        label: "Teodoro Schmid",
    },
    {
        value: "Toltén",
        label: "Toltén",
    },
    {
        value: "Vilcún",
        label: "Vilcún",
    },
    {
        value: "VILL",
        label: "Villarrica",
    },
    {
        value: "Cholchol",
        label: "Cholchol",
    },
    {
        value: "MINI",
        label: "Angol",
    },
    {
        value: "COLL",
        label: "Collipulli",
    },
    {
        value: "CURC",
        label: "Curacautín",
    },
    {
        value: "Ercilla",
        label: "Ercilla",
    },
    {
        value: "Lonquimay",
        label: "Lonquimay",
    },
    {
        value: "Los Sauces",
        label: "Los Sauces",
    },
    {
        value: "Lumaco",
        label: "Lumaco",
    },
    {
        value: "PURE",
        label: "Purén",
    },
    {
        value: "RNCO",
        label: "Renaico",
    },
    {
        value: "TRAI",
        label: "Traiguén",
    },
    {
        value: "VICT",
        label: "Victoria",
    },
    {
        value: "VALD",
        label: "Valdivia",
    },
    {
        value: "Corral",
        label: "Corral",
    },
    {
        value: "LANC",
        label: "Lanco",
    },
    {
        value: "LLAG",
        label: "Los Lagos",
    },
    {
        value: "Máfil",
        label: "Máfil",
    },
    {
        value: "SJMA",
        label: "Mariquina",
    },
    {
        value: "PAIL",
        label: "Paillaco",
    },
    {
        value: "PANG",
        label: "Panguipulli",
    },
    {
        value: "LAUN",
        label: "La Unión",
    },
    {
        value: "FUTR",
        label: "Futrono",
    },
    {
        value: "LRAN",
        label: "Lago Ranco",
    },
    {
        value: "RIOB",
        label: "Río Bueno",
    },
    {
        value: "PMON",
        label: "Puerto Montt",
    },
    {
        value: "CALB",
        label: "Calbuco",
    },
    {
        value: "Cochamó",
        label: "Cochamó",
    },
    {
        value: "FRES",
        label: "Fresia",
    },
    {
        value: "FRUT",
        label: "Frutillar",
    },
    {
        value: "LMUE",
        label: "Los Muermos",
    },
    {
        value: "LLAN",
        label: "Llanquihue",
    },
    {
        value: "PARG",
        label: "Maullín",
    },
    {
        value: "PVAR",
        label: "Puerto Varas",
    },
    {
        value: "CAST",
        label: "Castro",
    },
    {
        value: "ANCU",
        label: "Ancud",
    },
    {
        value: "CHON",
        label: "Chonchi",
    },
    {
        value: "Curaco de Vélez",
        label: "Curaco de Vélez",
    },
    {
        value: "DALC",
        label: "Dalcahue",
    },
    {
        value: "Puqueldón",
        label: "Puqueldón",
    },
    {
        value: "Queilén",
        label: "Queilén",
    },
    {
        value: "QUEL",
        label: "Quellón",
    },
    {
        value: "QUEM",
        label: "Quemchi",
    },
    {
        value: "ACHA",
        label: "Quinchao",
    },
    {
        value: "OSOR",
        label: "Osorno",
    },
    {
        value: "POCT",
        label: "Puerto Octay",
    },
    {
        value: "PURR",
        label: "Purranque",
    },
    {
        value: "PUYG",
        label: "Puyehue",
    },
    {
        value: "RNEG",
        label: "Río Negro",
    },
    {
        value: "San Juan de la Costa",
        label: "San Juan de la Costa",
    },
    {
        value: "SPAB",
        label: "San Pablo",
    },
    {
        value: "CHAI",
        label: "Chaitén",
    },
    {
        value: "Futaleufú",
        label: "Futaleufú",
    },
    {
        value: "HORP",
        label: "Hualaihué",
    },
    {
        value: "Palena",
        label: "Palena",
    },
    {
        value: "COYH",
        label: "Coyhaique",
    },
    {
        value: "Lago Verde",
        label: "Lago Verde",
    },
    {
        value: "PCHA",
        label: "Aisén",
    },
    {
        value: "PCIS",
        label: "Cisnes",
    },
    {
        value: "Guaitecas",
        label: "Guaitecas",
    },
    {
        value: "COCH",
        label: "Cochrane",
    },
    {
        value: "O’Higgins",
        label: "O’Higgins",
    },
    {
        value: "Tortel",
        label: "Tortel",
    },
    {
        value: "CHCH",
        label: "Chile Chico",
    },
    {
        value: "Río Ibáñez",
        label: "Río Ibáñez",
    },
    {
        value: "PUNT",
        label: "Punta Arenas",
    },
    {
        value: "Laguna Blanca",
        label: "Laguna Blanca",
    },
    {
        value: "Río Verde",
        label: "Río Verde",
    },
    {
        value: "San Gregorio",
        label: "San Gregorio",
    },
    {
        value: "Cabo de Hornos (Ex. Navarino)",
        label: "Cabo de Hornos (Ex. Navarino)",
    },
    {
        value: "Antártica",
        label: "Antártica",
    },
    {
        value: "PORV",
        label: "Porvenir",
    },
    {
        value: "Primavera",
        label: "Primavera",
    },
    {
        value: "Timaukel",
        label: "Timaukel",
    },
    {
        value: "PNAT",
        label: "Natales",
    },
    {
        value: "Torres del Paine",
        label: "Torres del Paine",
    },
    {
        value: "STGO",
        label: "Santiago",
    },
    {
        value: "LOSC",
        label: "Cerrillos",
    },
    {
        value: "CNAV",
        label: "Cerro Navia",
    },
    {
        value: "CCHA",
        label: "Conchalí",
    },
    {
        value: "ELBO",
        label: "El Bosque",
    },
    {
        value: "ECEN",
        label: "Estación Central",
    },
    {
        value: "HUEC",
        label: "Huechuraba",
    },
    {
        value: "INDE",
        label: "Independencia",
    },
    {
        value: "LACI",
        label: "La Cisterna",
    },
    {
        value: "LAFL",
        label: "La Florida",
    },
    {
        value: "LAGR",
        label: "La Granja",
    },
    {
        value: "LAPI",
        label: "La Pintana",
    },
    {
        value: "LARE",
        label: "La Reina",
    },
    {
        value: "LCON",
        label: "Las Condes",
    },
    {
        value: "LOBA",
        label: "Lo Barnechea",
    },
    {
        value: "LOES",
        label: "Lo Espejo",
    },
    {
        value: "LOPR",
        label: "Lo Prado",
    },
    {
        value: "MACU",
        label: "Macul",
    },
    {
        value: "MIPU",
        label: "Maipú",
    },
    {
        value: "NUNO",
        label: "Ñuñoa",
    },
    {
        value: "PEDR",
        label: "Pedro Aguirre Cerda",
    },
    {
        value: "PLOL",
        label: "Peñalolén",
    },
    {
        value: "PROV",
        label: "Providencia",
    },
    {
        value: "PUDA",
        label: "Pudahuel",
    },
    {
        value: "QILI",
        label: "Quilicura",
    },
    {
        value: "QNOR",
        label: "Quinta Normal",
    },
    {
        value: "RECO",
        label: "Recoleta",
    },
    {
        value: "RENC",
        label: "Renca",
    },
    {
        value: "SJOA",
        label: "San Joaquín",
    },
    {
        value: "SMIG",
        label: "San Miguel",
    },
    {
        value: "SRAM",
        label: "San Ramón",
    },
    {
        value: "VITA",
        label: "Vitacura",
    },
    {
        value: "PALT",
        label: "Puente Alto",
    },
    {
        value: "PIRQ",
        label: "Pirque",
    },
    {
        value: "San José de Maipo",
        label: "San José de Maipo",
    },
    {
        value: "COLI",
        label: "Colina",
    },
    {
        value: "LAMP",
        label: "Lampa",
    },
    {
        value: "TILT",
        label: "Tiltil",
    },
    {
        value: "SBER",
        label: "San Bernardo",
    },
    {
        value: "BUIN",
        label: "Buin",
    },
    {
        value: "CTAN",
        label: "Calera de Tango",
    },
    {
        value: "PAIN",
        label: "Paine",
    },
    {
        value: "MELI",
        label: "Melipilla",
    },
    {
        value: "Alhué",
        label: "Alhué",
    },
    {
        value: "CRCV",
        label: "Curacaví",
    },
    {
        value: "María Pinto",
        label: "María Pinto",
    },
    {
        value: "San Pedro",
        label: "San Pedro",
    },
    {
        value: "TALA",
        label: "Talagante",
    },
    {
        value: "ELPA",
        label: "El Monte",
    },
    {
        value: "LONQ",
        label: "Isla de Maipo",
    },
    {
        value: "PHUR",
        label: "Padre Hurtado",
    },
    {
        value: "PENA",
        label: "Peñaflor",
    },
];

export const categoryType = [
    {
        value: "PAYMENT",
        label: "Payment",
    },
    {
        value: "ACCOUNTING",
        label: "Accounting",
    },
    {
        value: "SHIPPING",
        label: "Shipping",
    },
    {
        value: "METAFIELD",
        label: "Metafield",
    },
    {
        value: "ERP",
        label: "ERP",
    },
    {
        value: "ADS",
        label: "ADS",
    },
    {
        value: "IDCHECK",
        label: "IDCHECK",
    },
    {
        value: "CRM",
        label: "CRM",
    },
    {
        value: "DELIVERY",
        label: "Delivery",
    },
    {
        value: "REVERSE_PROXY",
        label: "Reverse Proxy",
    },
];

export const kindAttributes = [
    {
        value: "SIZE",
        label: "Size",
    },
    {
        value: "COLOR",
        label: "Color",
    },
    {
        value: "MATERIAL",
        label: "Material",
    },
    {
        value: "TEXTURE",
        label: "Texture",
    },
    {
        value: "FLAVOUR",
        label: "Flavour",
    },
];

export const pichUpTimes = [
    {
        value: "Usually ready in 1h",
        label: "Usually ready in 1h",
    },
    {
        value: "Usually ready in 2h",
        label: "Usually ready in 2h",
    },
    {
        value: "Usually ready in 4h",
        label: "Usually ready in 4h",
    },
    {
        value: "Usually ready in 24h",
        label: "Usually ready in 24h",
    },
    {
        value: "Usually ready in 2-4 days",
        label: "Usually ready in 2-4 days",
    },
    {
        value: "Usually ready in 5+ days",
        label: "Usually ready in 5+ days",
    },
];

export const transparencyOptions = [
    { value: "NONE", label: "No Transparency" },
    { value: "LOW", label: "Low" },
    { value: "MEDIUM", label: "Medium" },
    { value: "HIGHT", label: "Height" },
];

export const ROLES = [
    {
        value: "CUSTOMER",
        label: "Customer",
    },
    {
        value: "OWNER",
        label: "Owner",
    },
    {
        value: "ADMIN",
        label: "Admin",
    },
];

export const REFERRAL_TYPES = [
    {
        value: "Default",
        label: "Default",
    },
    {
        value: "Special",
        label: "Special",
    },
    {
        value: "Direct",
        label: "Direct",
    },
    {
        value: "2nd_Order",
        label: "2nd Order",
    },
    {
        value: "3rd_Order",
        label: "3rd Order",
    },
];

export const VERIFICATION_TYPES = [
    {
        value: false,
        label: "Reject",
    },
    {
        value: true,
        label: "Accept",
    },
];

export const KANBAN_LANES = {
    Procesing: { color: "metalic", status: "PENDING" },
    Approved: { color: "info", status: ["APPROVED", "READY"] },
    Assigned: { color: "info", status: "ASSIGNED" },
    "On Route": { color: "info", status: "DELIVERY_STARTED" },
    Completed: { color: "success", status: ["DELIVERED", "DELIVERY_FINISHED"] },
    Cancelled: { color: "error", status: ["DECLINED", "REFUNDED", "VOID"] },
};

export const PAYMENT_METHOD = [
    {
        value: "CASH",
        label: "Cash",
    },
    {
        value: "AEROPAY",
        label: "Aeropay",
    },
    {
        value: "DEBIT_CARD",
        label: "Debit card",
    },
    {
        value: "CREDIT_CARD",
        label: "Credit card",
    },
    {
        value: "CHECK",
        label: "Check",
    },
];

export const DELIVERY_WINDOW_HOURS = [
    { value: "8:00 AM - 9:00 AM", label: "8am - 9am" },
    { value: "9:00 AM - 10:00 AM", label: "9am - 10am" },
    { value: "10:00 AM - 11:00 AM", label: "10am - 11am" },
    { value: "11:00 AM - 12:00 PM", label: "11am - 12pm" },
    { value: "12:00 PM - 1:00 PM", label: "12pm - 1pm" },
    { value: "1:00 PM - 2:00 PM", label: "1pm - 2pm" },
    { value: "2:00 PM - 3:00 PM", label: "2pm - 3pm" },
    { value: "3:00 PM - 4:00 PM", label: "3pm - 4pm" },
    { value: "4:00 PM - 5:00 PM", label: "4pm - 5pm" },
    { value: "5:00 PM - 6:00 PM", label: "5pm - 6pm" },
    { value: "6:00 PM - 7:00 PM", label: "6pm - 7pm" },
    { value: "7:00 PM - 8:00 PM", label: "7pm - 8pm" },
    { value: "8:00 PM - 9:00 PM", label: "8pm - 9pm" },
    { value: "9:00 PM - 10:00 PM", label: "9pm - 10pm" },
    { value: "10:00 PM - 11:00 PM", label: "10pm - 11pm" },
];

export const NOT_AVAILABLE = "Not Available";

export const EVENT_TYPE = [
    {
        value: "ORDER_NEW",
        label: "Order New",
    },
    {
        value: "ORDER_APPROVED",
        label: "Order Approved",
    },
    {
        value: "ORDER_COMPLETED",
        label: "Order Completed",
    },
    {
        value: "ORDER_REJECTED",
        label: "Order Rejected",
    },
    {
        value: "ORDER_ON_ROUTE",
        label: "Order On Route",
    },
    {
        value: "ORDER_STATUS_CHANGED",
        label: "Order Status Changed",
    },
    {
        value: "CUOPON_ADDED",
        label: "Cuopon Added",
    },
    {
        value: "RESET_USER_PASSWORD_TEMP_PASSWORD",
        label: "Reset User Password Temp Password",
    },
    {
        value: "RESET_USER_PASSWORD_CONFIRMATION_CODE",
        label: "Reset User Password Confirmation Code",
    },
    {
        value: "USER_SIGNUP",
        label: "User Signup",
    },
];

export const EVENT_TYPE_LISTTRACK = [
    {
        value: "ORDER_NEW",
        label: "Order New",
    },
    {
        value: "ORDER_NEW_PICKUP",
        label: "Order New Pickup",
    },
    {
        value: "ORDER_NEW_DELIVERY",
        label: "Order New Delivery",
    },
    {
        value: "ORDER_APPROVED",
        label: "Order Approved",
    },
    {
        value: "ORDER_APPROVED_PICKUP",
        label: "Order Approved Pickup",
    },
    {
        value: "ORDER_APPROVED_DELIVERY",
        label: "Order Approved Delivery",
    },
    {
        value: "ORDER_COMPLETED",
        label: "Order Completed",
    },
    {
        value: "ORDER_COMPLETED_PICKUP",
        label: "Order Completed Pickup",
    },
    {
        value: "ORDER_COMPLETED_DELIVERY",
        label: "Order Completed Delivery",
    },
    {
        value: "ORDER_REJECTED",
        label: "Order Rejected",
    },
    {
        value: "ORDER_REJECTED_PICKUP",
        label: "Order Rejected Pickup",
    },
    {
        value: "ORDER_REJECTED_DELIVERY",
        label: "Order Rejected Delivery",
    },
    {
        value: "ORDER_ON_ROUTE",
        label: "On Route",
    },
    {
        value: "ORDER_STATUS_CHANGED",
        label: "Order Status Changed",
    },
    {
        value: "CUOPON_ADDED",
        label: "Cuopon Added",
    },
    {
        value: "RESET_USER_PASSWORD_TEMP_PASSWORD",
        label: "Reset User Password Temp Password",
    },
    {
        value: "RESET_USER_PASSWORD_CONFIRMATION_CODE",
        label: "Reset User Password Confirmation Code",
    },
    {
        value: "USER_SIGNUP",
        label: "User Signup",
    },
];

export const SUBSCRIPTION_SOURCE_TYPE = [
    { value: "SIGNUP", label: "Signup" },
    {
        value: "POPUP",
        label: "Popup",
    },
    {
        value: "ACCOUNT",
        label: "Account",
    },
    {
        value: "FOOTER",
        label: "Footer",
    },
];

export const SEARCH_ORDER_FILTER_OPTIONS = [
    { value: null, label: "Search" },
    { value: "address", label: "Address" },
    { value: "customer", label: "Customer" },
    { value: "email", label: "Email" },
    { value: "cuopon", label: "Coupon" },
    { value: "order_number", label: "Order Number" },
];

export const DAYS_OF_WEEK = [
    { day: "Sunday" },
    {
        day: "Monday",
    },
    {
        day: "Tuesday",
    },
    {
        day: "Wednesday",
    },
    {
        day: "Thursday",
    },
    {
        day: "Friday",
    },
    {
        day: "Saturday",
    },
];

export const RESERVATION_MODE_OPTIONS = [
    {
        label: "Pickup",
        value: "PICKUP",
    },
    {
        label: "Delivery",
        value: "DELIVERY",
    },
    {
        label: "All",
        value: "ALL",
    },
];

export const PAYMENTS_TYPES_OPTIONS = [
    {
        label: "ACH",
        value: "ACH",
    },
    {
        label: "Cash",
        value: "CASH",
    },
    {
        label: "Debit",
        value: "DEBIT",
    },
];

export const DAYSOFWEEK = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
];

export const TYPEOFSERVICE = [
    {
        label: "Start Up",
        value: "STARTUP",
    },
    {
        label: "Scale",
        value: "SCALE",
    },
];

export const GEO_TYPE = [
    {
        label: "Zip",
        value: "ZIP",
    },
    {
        label: "Radius",
        value: "RADIUS",
    },
    {
        label: "Geofence",
        value: "GEOFENCE",
    },
];

export const APP_CONFIG = [
    {
        handler: "metafield",
        description:
            "This app allows you to configure the progresive web app configuration, including the app name, app icon, app theme color, app background color, app display mode, app orientation, app start url, app scope, app service worker, app manifest, app meta tags, app meta description, app meta keywords, app meta author, app meta robots",
        icon: "https://assets.gapcommerce.com/admin-apps-images/default.png",
        hide: true,
    },
    {
        handler: "jane",
        description:
            "Jane is an online cannabis marketplace and e-commerce platform that integrates in real-time with several POS. Jane enables customers to shop online for products at their local dispensaries the same way they shop for everything else",
        icon: "https://assets.gapcommerce.com/admin-apps-images/jane.png",
        hide: true,
    },
    {
        handler: "treez",
        description:
            "Treez POS (Point of Sale) is a software platform designed for cannabis dispensaries to manage their sales, inventory, and customer relationships.",
        icon: "https://assets.gapcommerce.com/admin-apps-images/treez.png",
        hide: false,
    },
    {
        handler: "berbix",
        description:
            "Berbix is an identity verification platform that allows businesses to quickly and securely verify the identities of their users. It uses a combination of document scanning, facial recognition, and other verification methods to confirm that a person is who they claim to be.",
        icon: "https://assets.gapcommerce.com/admin-apps-images/berbix.png",
        hide: true,
    },
    {
        handler: "redirect",
        description:
            "This app allows you to create redirect rules to permanently redirect URLs to other URLs",
        icon: "https://assets.gapcommerce.com/admin-apps-images/redirect.png",
        hide: false,
    },
    {
        handler: "reverse_proxy",
        description:
            "This app allows you to create reverse proxy rules to permanently redirect URLs to other URLs",
        icon: "https://assets.gapcommerce.com/admin-apps-images/default.png",
    },
    {
        handler: "reverse_proxy",
        description:
            "This app allows you to create reverse proxy rules to permanently redirect URLs to other URLs",
        icon: "https://assets.gapcommerce.com/admin-apps-images/default.png",
    },
    {
        handler: "reverse_proxy",
        description:
            "This app allows you to create reverse proxy rules to permanently redirect URLs to other URLs",
        icon: "https://assets.gapcommerce.com/admin-apps-images/default.png",
    },
    {
        handler: "klaviyo",
        description:
            "Klaviyo is a marketing automation platform designed to help businesses build and grow customer relationships through email and SMS marketing. It allows businesses to create personalized, targeted campaigns by leveraging customer data, such as purchase history and browsing behavior. With Klaviyo, companies can segment their audience, automate communication, and track the performance of their campaigns in real-time.",
        icon: "https://assets.gapcommerce.com/admin-apps-images/klaviyo.png",
        hide: false,
    },
    {
        handler: "quickbook",
        description:
            "QuickBooks is an accounting software developed by Intuit that helps businesses manage their finances. It provides tools for tasks such as invoicing, expense tracking, payroll, and tax preparation.",
        icon: "https://assets.gapcommerce.com/admin-apps-images/quickbooks.png",
        hide: true,
    },
    {
        handler: "min_zip_by_code",
        description:
            "Allow to set the minimum by zip code for delivery orders. Note, this app is deprecated, instead use the Schedule Delivery Config",
        icon: "https://assets.gapcommerce.com/admin-apps-images/default.png",
        hide: true,
    },
    {
        handler: "omnisend",
        description:
            "Omnisend is an e-commerce marketing automation platform that helps businesses create and manage email and SMS marketing campaigns. It allows companies to send personalized messages to customers based on their behavior, such as browsing habits, purchase history, or cart abandonment.",
        icon: "https://assets.gapcommerce.com/admin-apps-images/omnisend.png",
        hide: false,
    },
    {
        handler: "onFleet",
        description:
            "OnFleet is a delivery management software platform that helps businesses optimize their delivery operations. It provides tools for managing and tracking deliveries, assigning drivers, routing, and communicating with customers in real-time.",
        icon: "https://assets.gapcommerce.com/admin-apps-images/onfleet.png",
        hide: false,
    },
    {
        handler: "feefo",
        description:
            "Feefo is a customer feedback and review platform that helps businesses collect, analyze, and showcase customer reviews. It allows companies to gather genuine feedback from verified customers, which can be used to improve products and services, build trust, and enhance their online reputation.",
        icon: "https://assets.gapcommerce.com/admin-apps-images/feefo.png",
        hide: true,
    },
    {
        handler: "alpineiq",
        description:
            "Alpine IQ is a customer data and marketing platform designed for businesses in the cannabis industry. It helps companies collect and analyze customer data to create personalized marketing campaigns and improve customer engagement.",
        icon: "https://assets.gapcommerce.com/admin-apps-images/alpine.png",
        hide: false,
    },
    {
        handler: "iterable",
        description:
            "Iterable is a marketing automation platform designed to help businesses create, manage, and optimize their marketing campaigns across various channels. It focuses on providing a unified approach to customer engagement through email marketing, SMS, push notifications, in-app messages, and more.",
        icon: "https://assets.gapcommerce.com/admin-apps-images/default.png",
        hide: true,
    },
    {
        handler: "listrack",
        description:
            "Iterable is a marketing automation platform that helps businesses create, manage, and optimize multi-channel marketing campaigns. It allows companies to engage with their customers through various channels, such as email, SMS, push notifications, and social media.",
        icon: "https://assets.gapcommerce.com/admin-apps-images/listrack.png",
        hide: true,
    },
    {
        handler: "idscan",
        description:
            "IDScan is a technology platform that provides tools for scanning, verifying, and authenticating identification documents, such as driver's licenses, passports, and ID cards. Businesses use IDScan to quickly verify the identity of customers, employees, or visitors by capturing and analyzing data from their IDs.",
        icon: "https://assets.gapcommerce.com/admin-apps-images/id_scan.png",
        hide: false,
    },
    {
        handler: "dutchie",
        description:
            "Dutchie is a point-of-sale (POS) and e-commerce platform specifically designed for the cannabis industry. It helps dispensaries and cannabis retailers manage their operations, including sales, inventory, and customer interactions.",
        icon: "https://assets.gapcommerce.com/admin-apps-images/dutchie.png",
        hide: true,
    },
    {
        handler: "stronghold",
        description:
            "Stronghold is a payment platform that focuses on facilitating transactions in a secure and efficient manner. It provides a range of financial services and solutions tailored for various needs.",
        icon: "https://assets.gapcommerce.com/admin-apps-images/stronghold.png",
        hide: false,
    },
    {
        handler: "aeropay",
        description:
            "Aeropay is a payment processing platform that focuses on providing businesses with solutions for handling electronic payments, particularly for industries that may face challenges with traditional payment systems. ",
        icon: "https://assets.gapcommerce.com/admin-apps-images/aeropay.png",
        hide: false,
    },
    {
        handler: "treezpay",
        description:
            "Treez is a payment processing platform that focuses on providing businesses with solutions for handling electronic payments, particularly for industries that may face challenges with traditional payment systems. ",
        icon: "https://assets.gapcommerce.com/admin-apps-images/default.png",
        hide: false,
    },
    {
        handler: "surfside",
        description:
            "Surfside is a company specializing in ads technology solutions, particularly for the cannabis industry.",
        icon: "https://assets.gapcommerce.com/admin-apps-images/surfside.png",
        hide: false,
    },
    {
        handler: "swifter",
        description:
            "Is a payment processing platform that focuses on providing businesses with solutions for handling electronic payments",
        icon: "https://assets.gapcommerce.com/admin-apps-images/swifter.png",
        hide: false,
    },
    {
        handler: "cova",
        description:
            "Cove POS is a point-of-sale (POS) system designed specifically for businesses in the cannabis industry.",
        icon: "https://assets.gapcommerce.com/admin-apps-images/cova.png",
        hide: true,
    },
    {
        handler: "facebook_catalog",
        description:
            "A Facebook Catalog is a tool provided by Meta (formerly Facebook) that businesses can use to manage and showcase their products on Facebook and Instagram.",
        icon: "https://assets.gapcommerce.com/admin-apps-images/facebook_catalog.png",
        hide: false,
    },
    {
        handler: "blaze",
        description:
            "Blaze POS is a point-of-sale (POS) system specifically designed for the cannabis industry. It provides a comprehensive suite of tools to manage various aspects of a cannabis retail operation.",
        icon: "https://assets.gapcommerce.com/admin-apps-images/default.png",
        hide: true,
    },
];

export const VARIABLES_TEMP_DEFAULT = [
    {
        label: "Store",
        value: [
            "{{.License}}",
            "{{.StoreLogo}}",
            "{{.StoreEmail}}",
            "{{.StoreDomain}}",
            "{{.StoreAddress}}",
            "{{.StoreDealsUrl}}",
            "{{.StoreLogoSmall}}",
            "{{.CompanyEmail}}",
            "{{.CompanyName}}",
            "{{.StorePhoneNumber}}",
        ],
    },
    {
        label: "Order",
        value: [
            "{{.Date}}",
            "{{.Email}}",
            "{{.OrderType}}",
            "{{.OrderNumber}}",
            "{{.OrderDetailsURL}}",
            "{{.DeliveryAddress.Address}}",
            "{{.DeliveryAddress.LastName}}",
            "{{.DeliveryAddress.FirstName}}",
        ],
    },
    {
        label: "Social",
        value: ["{{.Yelp}}", "{{.Twitter}}", "{{.Facebook}}", "{{.Instagram}}"],
    },
    {
        label: "Order Line Items",
        value: [
            "{{ $ln.Price }}",
            "{{ $ln.Total }}",
            "{{ $ln.Gram }}",
            "{{ $ln.Name }}",
            "{{ $ln.InStock }}",
            "{{ $ln.Quantity }}",
            "{{ $ln.Thumbnail }}",
            "{{ range $i, $ln:= .LineItems }}",
        ],
    },
    {
        label: "Order Summary",
        value: [
            "${{formatPrice .TotalTax}}",
            "${{formatPrice .TotalPrice}}",
            "${{formatPrice .TotalDelivery}}",
            "${{formatPrice .SubtotalPrice}}",
            "${{formatPrice .TotalDiscounts}}",
            "${{formatPrice .TotalServiceFee}}",
        ],
    },
    {
        label: "Treez POS",
        value: ["{{.TreezTicketNumber}}"],
    },
];

export const VARIABLES_TEMP_BUSSINES_CONTACT = [
    {
        label: "Store",
        value: [
            "{{.StoreLogo}}",
            "{{.StoreEmail}}",
            "{{.StoreDomain}}",
            "{{.StoreAddress}}",
            "{{.StoreLogoSmall}}",
            "{{.CompanyName}}",
            "{{.StorePhoneNumber}}",
        ],
    },
    {
        label: "Social",
        value: ["{{.Yelp}}", "{{.Twitter}}", "{{.Facebook}}", "{{.Instagram}}"],
    },
    {
        label: "Request Info",
        value: ["{{.Email}}", "{{.Name}}", "{{.Message}}"],
    },
];
