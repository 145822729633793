import React, { useState } from "react";
import {
    Portlet,
    Section,
    SectionTitle,
    Table,
    Badge,
    Spinner,
} from "@/components";
import { Button, Col, Row } from "reactstrap";
import { useMutation } from "@apollo/react-hooks";
import { MUTATION_UPDATE_STORE } from "../../apollo/module-operations/store";
import _ from "lodash";
import omit from "lodash/omit";
import { useConfirm, useMeta, useNotification } from "@/hooks";
import ScheduleForm from "./ScheduleForm";
import SchedulesFormJSON from "./SchedulesFormJSON";
import { currencyFormat, formatUpdateOrderInput } from "utils/utils";

const DeliverySchedulesForm = ({ inputs, setInputs, loading, stores }) => {
    const notify = useNotification();
    const { user } = useMeta();
    const [showForm, setShowForm] = useState(false);
    const [showFormJson, setShowFormJson] = useState(false);
    const [isCreate, setIsCreate] = useState(true);
    const [rowSelect, setRowSelect] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const [mutate] = useMutation(MUTATION_UPDATE_STORE, {
        refetchQueries: [],
        onCompleted: resp => {
            let input = _.cloneDeep(resp.updateStore);

            setInputs(input);
            handleCancel();
            setRowSelect(null);
            setIsDelete(false);
            notify(
                `Delivery Zone Was ${
                    isCreate ? "Created" : !isDelete ? "Updated" : "Removed"
                } Successfully"`,
                "success",
            );
        },
        onError: () => {
            setIsUpdating(false);
            notify(
                `Error ${
                    isCreate ? "Updating" : !isDelete ? "Creating" : "Removing"
                }  The Delivery Zone, If The Error Persist Please Contact Support.`,
                "error",
            );
        },
    });

    const [confirm, launch] = useConfirm(async () => {
        const newShedules = _.filter(
            inputs.delivery_schedules,
            (i, index) => index !== rowSelect,
        );

        const updateInputs = {
            ...omit(
                {
                    ...inputs,
                    delivery_schedules: newShedules,
                },
                "ignore_whitespace",
            ),
        };

        /*    const { errors } = await mutate({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: formatUpdateOrderInput(updateInputs),
            },
        });

        if (!errors) {
            setInputs(formatSetOrderInput(updateInputs));
        }
 */
        mutate({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: formatUpdateOrderInput(updateInputs),
            },
        });
    }, `Are You Sure You Want To Remove The Delivery Zone ?`);

    const columns = [
        {
            dataField: "name",
            text: "Name",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.name || ""}`}</div>;
            },
        },
        {
            dataField: "express_menu_config",
            text: "Express Service",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Badge
                        className={
                            cell?.active ? "status__success" : "status__error"
                        }
                        label={cell?.active ? "Yes" : "No"}
                    />
                );
            },
        },
        {
            dataField: "min",
            text: "Minimum Cost",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${currencyFormat(row.min)}`}</div>;
            },
        },
        {
            dataField: "free_after",
            text: "Free After",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${currencyFormat(row.free_after)}`}</div>;
            },
        },
        {
            dataField: "delivery_fee",
            text: "Delivery Fee",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${currencyFormat(row.delivery_fee)}`}</div>;
            },
        },
        {
            dataField: "stores_provider_id",
            text: "Store Provider",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <Badge className={"status__success"} label={cell} />;
            },
        },
        {
            dataField: "geo_type",
            text: "Geo Type Active",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Badge className={"status__info"} label={cell ?? "ZIP"} />
                );
            },
        },
    ];

    const actions = [
        {
            label: "Update",
            action: async (cell, row, rowIndex) => {
                setIsCreate(false);
                setRowSelect(rowIndex);
                setShowForm(true);
            },
        },
        {
            label: "Delete",
            action: async (cell, row, rowIndex) => {
                setIsDelete(true);
                setIsCreate(false);
                setRowSelect(rowIndex);
                launch();
            },
        },
    ];

    const handlerUpdatingOrCreate = array => {
        setIsUpdating(true);

        mutate({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: formatUpdateOrderInput({
                    ...omit(
                        {
                            ...inputs,
                            delivery_schedules: array,
                        },
                        "ignore_whitespace",
                    ),
                }),
            },
        });
    };

    const handlerUpdatingOrCreateJSON = inputs => {
        setIsUpdating(true);

        mutate({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: formatUpdateOrderInput(inputs),
            },
        });
    };

    const handleCancel = () => {
        setIsUpdating(false);
        setShowForm(false);
        setShowFormJson(false);
    };

    const _deliveryZones = (inputs && inputs.delivery_schedules) || [];

    return (
        <>
            {!loading ? (
                <Portlet
                    className="business__settings"
                    header={{
                        title: "Delivery Schedules",
                        subtitle: "Delivery Schedules Information",
                    }}
                    sticky
                    toolbar={[
                        <Button
                            className="ml-3"
                            color="secondary"
                            disabled={isUpdating || showForm || showFormJson}
                            key="addDeliveryZone"
                            onClick={() => {
                                setIsCreate(true);
                                setShowForm(true);
                            }}
                        >
                            <i className="fas fa-plus" />
                            Add Delivery Zone
                        </Button>,
                        <Button
                            className="ml-3"
                            color="primary"
                            disabled={isUpdating || showForm || showFormJson}
                            key="updateFromJSON"
                            onClick={() => {
                                setIsCreate(true);
                                setShowFormJson(true);
                            }}
                        >
                            <i className="fas fa-edit" />
                            Update Form JSON
                        </Button>,
                    ]}
                >
                    {showForm && (
                        <ScheduleForm
                            context="Delivery"
                            defaultCoordinates={{
                                lat: inputs?.latitude,
                                lng: inputs?.longitude,
                            }}
                            handleCancel={handleCancel}
                            handlerUpdatingOrCreate={handlerUpdatingOrCreate}
                            isCreate={isCreate}
                            isUpdating={isUpdating}
                            schedules={inputs.delivery_schedules}
                            selectSchedule={rowSelect}
                            stores={stores}
                        />
                    )}
                    {showFormJson && (
                        <SchedulesFormJSON
                            context="Delivery"
                            handleCancel={handleCancel}
                            handlerUpdatingOrCreate={
                                handlerUpdatingOrCreateJSON
                            }
                            inputs={inputs}
                            isUpdating={isUpdating}
                            setInputs={setInputs}
                        />
                    )}
                    <Section className="pb-4">
                        <Row>
                            <Col>
                                <SectionTitle md>Delivery Zones</SectionTitle>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table
                                    actions={actions}
                                    columns={columns}
                                    data={_deliveryZones}
                                    //expandRow={expandRow}
                                    keyField="name"
                                    loading={loading}
                                />
                            </Col>
                        </Row>
                    </Section>
                </Portlet>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
            {confirm}
        </>
    );
};

export default DeliverySchedulesForm;
