import { useEffect } from "react";
import { useMeta } from "base/hooks";

const initializePendo = ({ key, id, email, userName, accountId }) => {
    if (!window.pendo) {
        (function (p, e, n, d, o) {
            o = p[d] = p[d] || {};
            o._q = [];
            [
                "initialize",
                "identify",
                "updateOptions",
                "pageLoad",
                "track",
            ].forEach(m => {
                o[m] =
                    o[m] ||
                    function (...args) {
                        o._q[m === "initialize" ? "unshift" : "push"](
                            [m].concat(args),
                        );
                    };
            });
            const y = document.createElement(e);
            y.async = true;
            y.src = `https://cdn.pendo.io/agent/static/${key}/pendo.js`;
            const z = document.getElementsByTagName(e)[0];
            z.parentNode.insertBefore(y, z);
        })(window, "script", document, "pendo");
    }

    window.pendo.initialize({
        visitor: {
            id,
            email,
            full_name: userName,
        },
        account: {
            id: accountId,
        },
    });
};

const PendoInitializer = () => {
    const { user } = useMeta();
    const key = process?.env?.REACT_APP_PENDO_API_KEY;

    if (!key) return null;

    useEffect(() => {
        initializePendo({
            id: user.id,
            userName: user.email?.split("@")[0] ?? "",
            accountId: user.accountId,
            email: user.email,
            key,
        });
    }, [user, key]);

    return null;
};

export default PendoInitializer;
