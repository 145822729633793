import React, { useState, useEffect } from "react";
import Content from "../../base/layout/content/Content";
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";
import useMeta from "../../base/hooks/useMeta.js";
import useForm from "../../base/hooks/form/useForm.js";
import useValidator from "../../base/hooks/form/useValidator.js";
import Navigation from "../../base/layout/content/Navigation";
import useFormActions from "../../base/hooks/form/useFormActions";
import useNotification from "../../base/hooks/components/useNotification";
import {
    MUTATION_UPDATE_STORE,
    QUERY_GET_STORE,
} from "../../apollo/module-operations/store";
import {
    MUTATION_ACTIVATE_APP,
    MUTATION_DEACTIVE_APP,
    QUERY_LIST_STORE_APPS,
} from "../../apollo/module-operations/app";
import { QUERY_SYSTEM_APP_LIST } from "../../apollo/module-operations/app";
import _ from "lodash";
import {
    timezonesOptions,
    currencyOptions,
    //taxTypeOptions,
    APP_CONFIG,
} from "../../config/constant";
import GeneralsForm from "./GeneralsForm";
import GoogleForm from "./GoogleForm";
//import SocialForm from "./SocialForm";
//import TaxForm from "./TaxForm";
import PickupSchedulesForm from "./PickupSchedules";
import DeliverySchedulesForm from "./DeliverySchedules";
import CheckoutForm from "./CheckoutForm";
import AdminSettingForm from "./AdminSettingForm";
import ChileExpressForm from "./ChileExpressForm";
import AppAuthorizeNetForm from "./AppAuthorizeNetForm";
import AppPayPalForm from "./AppPayPalForm";
import AppQuickBookForm from "./AppQuickBookForm";
import StripeForm from "./StripeForm";
import WebPayForm from "./WebPayForm";
import BlindDataForm from "./BlindDataForm";
import FacebookCatalogForm from "./FacebookCatalogForm";
import StoreUsers from "../user/StoreUsers";
import { permission } from "../../config/auth/permissions";
import MetafieldForm from "./MetafieldForm";
import NotificationSettingForm from "./NotificationSettingForm";
import MeasureRequestForm from "./MeasureRequestForm";
import PriceTableForm from "./PriceTableForm";
import { useParams } from "react-router-dom";
import BlazeForm from "./BlazeForm";
import OnFleetForm from "./OnFleetForm";
import LeafLogixForm from "./LeafLogixForm";
import CovaForm from "./CovaForm";
import DutchieForm from "./DutchieForm";
import TreezForm from "./TreezForm";
import CustomizationsForm from "./CustomizationsForm";
import JaneForm from "./JaneForm";
import BerbixForm from "./BerbixForm";
import IdScanForm from "./IdScanForm";
import RedirectForm from "./RedirectForm";
import ReverseProxyForm from "./ReverseProxyForm";
import MinZipByCodeForm from "./MinZipByCode";
import KlaviyoForm from "./KlaviyoForm";
import OmnisendForm from "./OmnisendForm";
import AlpineIOForm from "./AlpineIOForm";
import IterableForm from "./IterableForm";
import FeefoForm from "./FeefoForm";
import SwifterForm from "./SwifterForm";
import StrongholdForm from "./StrongholdForm";
import AeropayForm from "./AeropayForm";
import TreezPayForm from "./TreezPay";
import SurfsideForm from "./SurfsideForm";
import ListTrackForm from "./ListTrackForm";
import AppsConfigForm from "./AppsConfigForm";
import Closures from "./Closures";
import { formatSetOrderInput, formatUpdateOrderInput } from "../../utils/utils";

const StoreForm = () => {
    const { user } = useMeta();
    const [loading, setLoading] = useState(true);
    const [loadingAction, setLoadingAction] = useState(null);
    const [storeApps, setStoreApps] = useState([]);
    const [installedApps, setInstalledApps] = useState([]);
    const [treezDispensary, setTreezDispensary] = useState([]);
    const [providers, setProviders] = useState([]);
    const [systemAppsOptions, setSystemAppsOptions] = useState([]);
    const [systemActiveApps, setSystemActiveApps] = useState([]);

    const [activeSection, setActiveSection] = useState(null);

    const notify = useNotification();

    const {
        inputs,
        genericChange,
        setInputs,
        inputChange,
        selectChange,
        selectValue,
    } = useForm();

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "name",
                method: "isEmpty",
                validWhen: false,
                message: "This name is required.",
            },
            {
                field: "first_name",
                method: "isEmpty",
                validWhen: false,
                message: "This first name is required.",
            },
            {
                field: "last_name",
                method: "isEmpty",
                validWhen: false,
                message: "This last name is required.",
            },
            {
                field: "license",
                method: "isEmpty",
                validWhen: false,
                message: "This license is required.",
            },
            {
                field: "email",
                method: "isEmpty",
                validWhen: false,
                message: "This email is required.",
            },
            {
                field: "phone_number",
                method: "isEmpty",
                validWhen: false,
                message: "This phone is required.",
            },
            {
                field: "address1",
                method: "isEmpty",
                validWhen: false,
                message: "This address1 is required.",
            },
            {
                field: "city",
                method: "isEmpty",
                validWhen: false,
                message: "This city is required.",
            },
            {
                field: "country_code",
                method: "isEmpty",
                validWhen: false,
                message: "This country_code is required.",
            },
            {
                field: "zip",
                method: "isEmpty",
                validWhen: false,
                message: "This zip is required.",
            },
            {
                field: "province_code",
                method: "isEmpty",
                validWhen: false,
                message: "This province_code is required.",
            },
            {
                field: "timezone",
                method: "isEmpty",
                validWhen: false,
                message: "This timezone is required.",
            },

            {
                field: "currency",
                method: "isEmpty",
                validWhen: false,
                message: "This currency is required.",
            },
            {
                field: "domain",
                method: "isEmpty",
                validWhen: false,
                message: "This domain is required.",
            },
            {
                field: "aws_profile",
                method: "isEmpty",
                validWhen: false,
                message: "This aws profile is required.",
            },
            {
                field: "aws_region",
                method: "isEmpty",
                validWhen: false,
                message: "This aws region is required.",
            },
            /*  {
                field: "algolia_order_index",
                method: "isEmpty",
                validWhen: false,
                message: "This algolia order index is required.",
            }, */
        ],
        inputs,
    );

    useQuery(QUERY_SYSTEM_APP_LIST, {
        skip: user.loading,
        notifyOnNetworkStatusChange: true,
        onCompleted: resp => {
            setSystemAppsOptions(resp.listApps);
        },
    });

    const { refetch } = useQuery(QUERY_LIST_STORE_APPS, {
        skip: user.loading,
        fetchPolicy: "network-only",
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
        },
        onCompleted: resp => {
            const ops = [];
            const activeApps = [];

            const apps = resp?.listStoreApps;

            _.forEach(apps, app => {
                if (app.status) {
                    activeApps.push(app);
                    ops.push({ value: app.id, label: app.name, app });
                }

                if (app.handler === "treez") {
                    const dispensaries = [];
                    _.forEach(app.credentials, store => {
                        dispensaries.push({
                            value: `${store.store_id}|${store.dispensary_name}`,
                            store_id: store.dispensary_name,
                            label: store.store_name,
                        });
                    });
                    setTreezDispensary(dispensaries);
                    const updateProviders = dispensaries.map(a => ({
                        value: a.store_id,
                        label: a.label,
                    }));
                    setProviders(updateProviders);
                }
            });
            setInstalledApps(activeApps);
            setStoreApps(ops);
        },
    });

    useQuery(QUERY_GET_STORE, {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
        },
        skip: user.loading,
        fetchPolicy: "network-only",
        onCompleted: resp => {
            let inputs = _.cloneDeep(resp.getStore);

            setInputs(
                formatSetOrderInput({
                    ...inputs,
                    from_tx_email: inputs?.from_tx_email,
                }),
            );

            setLoading(false);
        },
    });

    const msn = {
        error: "The System could not :type The Selected App",
        success: "The System :type the App Successfully",
    };

    const updateAppCallback = (resp, action, type) => {
        let message;
        if (type === "success") {
            message = _.replace(msn.success, ":type", action);
        } else {
            message = _.replace(msn.error, ":type", action);
        }

        //window.location.reload();
        notify(message, type);
    };

    const [activateApp] = useMutation(MUTATION_ACTIVATE_APP, {
        refetchQueries: [],
        onCompleted: resp => {
            refetch();
            setLoadingAction(null);
            updateAppCallback(resp, "Activate", "success");
        },
        onError: () => {
            updateAppCallback(null, "Activate", "error");
        },
    });

    const [deactivateApp] = useMutation(MUTATION_DEACTIVE_APP, {
        refetchQueries: [],
        onCompleted: resp => {
            refetch();
            setActiveSection("INSTALL");
            setLoadingAction(null);
            updateAppCallback(resp, "Deactivate", "success");
        },
        onError: () => {
            updateAppCallback(null, "Deactivate", "error");
        },
    });

    const options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: formatUpdateOrderInput(inputs),
        },
    };

    const { createOrUpdateButton } = useFormActions(
        "id",
        "Store",
        "",
        MUTATION_UPDATE_STORE,
        [
            {
                query: QUERY_GET_STORE,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
            "getStore",
        ],
        options,
        "",
        isValid,
        isSubmited,
    );

    // Redirecting to Shipping section when comes from
    // Shippo authentication flow.
    const params = useParams();

    const fromShippo = params["shippo-oauth-redirect"];

    useEffect(() => {
        fromShippo && setActiveSection("SHIPPING");
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (systemAppsOptions?.length > 0) {
            const defaultIcon = "../assets/images/apps/default.png";

            const allApps = _.unionBy(
                systemAppsOptions,
                installedApps,
                "handler",
            );

            const storeAppsMap = new Map(
                installedApps?.map(app => [app?.handler, app]),
            );

            // Create a map of valid configurations from APP_CONFIG
            const validAppConfig = new Map(
                APP_CONFIG.map(detail => [detail.handler, detail]),
            );

            const listApp = _.sortBy(allApps, ["name"])
                ?.map(systemApp => {
                    const appDetail =
                        validAppConfig.get(systemApp.handler) || {};
                    const isInstalled =
                        storeAppsMap.has(systemApp.handler) ?? false;

                    return {
                        app: systemApp,
                        appDetail,
                        isInstalled,
                    };
                })
                ?.filter(
                    ({ appDetail, isInstalled }) =>
                        isInstalled || !appDetail.hide,
                )
                ?.map(({ app, appDetail, isInstalled }) => ({
                    app,
                    description: appDetail.description || "",
                    icon: appDetail.icon || defaultIcon,
                    isInstalled,
                }));

            setSystemActiveApps(listApp);
        }
    }, [systemAppsOptions, installedApps]);

    return (
        <>
            <Content>
                <Navigation
                    activeKey={activeSection}
                    items={[
                        {
                            label: "General information",
                            icon: "far fa-building",
                            invalid:
                                validator.name.isInvalid ||
                                validator.first_name.isInvalid ||
                                validator.last_name.isInvalid ||
                                validator.license.isInvalid ||
                                validator.email.isInvalid ||
                                validator.phone_number.isInvalid ||
                                validator.address1.isInvalid ||
                                validator.city.isInvalid ||
                                validator.zip.isInvalid ||
                                validator.province_code.isInvalid ||
                                validator.timezone.isInvalid ||
                                validator.currency.isInvalid,
                            render: (
                                <GeneralsForm
                                    accountId={user.accountId}
                                    currencyOptions={currencyOptions}
                                    inputChange={inputChange}
                                    inputs={inputs}
                                    loading={loading}
                                    selectChange={selectChange}
                                    selectValue={selectValue}
                                    setInputs={setInputs}
                                    storeId={user.storeId}
                                    timezonesOptions={timezonesOptions}
                                    toolbar={[createOrUpdateButton]}
                                    validator={validator}
                                />
                            ),
                        } /* 
                        {
                            label: "Features",
                            icon: "fas fa-store",
                            invalid: false,
                            render: (
                                <FeaturesForm
                                    genericChange={genericChange}
                                    inputs={inputs}
                                    loading={loading}
                                    toolbar={[createOrUpdateButton]}
                                />
                            ),
                        }, */,
                        {
                            label: "Google",
                            icon: "fab fa-google",
                            invalid: false,
                            render: (
                                <GoogleForm
                                    inputChange={inputChange}
                                    inputs={inputs}
                                    loading={loading}
                                    toolbar={[createOrUpdateButton]}
                                    validator={validator}
                                />
                            ),
                        },
                        /*  {
                            label: "Social",
                            icon: "fab fa-instagram",
                            invalid: false,
                            render: (
                                <SocialForm
                                    inputChange={inputChange}
                                    inputs={inputs}
                                    loading={loading}
                                    toolbar={[createOrUpdateButton]}
                                    validator={validator}
                                />
                            ),
                        }, */
                        /*   {
                            label: "Tax",
                            icon: "fas fa-credit-card",
                            invalid: false,
                            render: (
                                <TaxForm
                                    inputChange={inputChange}
                                    inputs={inputs}
                                    loading={loading}
                                    selectChange={selectChange}
                                    selectValue={selectValue}
                                    taxTypeOptions={taxTypeOptions}
                                    toolbar={[createOrUpdateButton]}
                                    validator={validator}
                                />
                            ),
                        }, */
                        {
                            label: "Notification Setting",
                            icon: "fas fa-envelope",
                            invalid: false,
                            render: (
                                <NotificationSettingForm
                                    inputChange={inputChange}
                                    inputs={inputs}
                                    loading={loading}
                                    toolbar={[createOrUpdateButton]}
                                />
                            ),
                        },
                        /*  {
                            label: "Opening Hours",
                            icon: "fas fa-clock",
                            permissions: permission.ADMIN_SETTINGS.read,
                            invalid: false,
                            render: (
                                <OpeningHoursForm
                                    inputChange={inputChange}
                                    inputs={inputs}
                                    loading={loading}
                                    setInputs={setInputs}
                                    toolbar={[createOrUpdateButton]}
                                    validator={validator}
                                />
                            ),
                        }, */
                        {
                            label: "Pickup Schedules",
                            icon: "fas fa-clock",
                            invalid: false,
                            render: (
                                <PickupSchedulesForm
                                    inputs={inputs}
                                    loading={loading}
                                    setInputs={setInputs}
                                    stores={providers}
                                />
                            ),
                        },
                        {
                            label: "Delivery Schedules",
                            icon: "fas fa-calendar",
                            invalid: false,
                            render: (
                                <DeliverySchedulesForm
                                    inputs={inputs}
                                    loading={loading}
                                    setInputs={setInputs}
                                    stores={providers}
                                />
                            ),
                        },
                        {
                            label: "Checkout",
                            icon: "fas fa-shopping-cart",
                            invalid: false,
                            render: (
                                <CheckoutForm
                                    inputChange={inputChange}
                                    inputs={inputs}
                                    loading={loading}
                                    selectChange={selectChange}
                                    selectValue={selectValue}
                                    setInputs={setInputs}
                                    stores={providers}
                                    toolbar={[createOrUpdateButton]}
                                    validator={validator}
                                />
                            ),
                        },
                        {
                            label: "Closures",
                            icon: "fas fa-calendar-times",
                            invalid: false,
                            render: (
                                <Closures
                                    inputs={inputs}
                                    loading={loading}
                                    setInputs={setInputs}
                                    stores={providers}
                                />
                            ),
                        },
                        {
                            label: "Customization",
                            icon: "fas fa-list-alt",
                            invalid: false,
                            render: (
                                <CustomizationsForm
                                    genericChange={genericChange}
                                    inputChange={inputChange}
                                    inputs={inputs}
                                    loading={loading}
                                    setInputs={setInputs}
                                    toolbar={[createOrUpdateButton]}
                                    validator={validator}
                                />
                            ),
                        },
                        {
                            label: "Administrator",
                            icon: "fas fa-user-cog",
                            permissions: permission.ADMIN_SETTINGS.read,
                            invalid: false,
                            render: (
                                <AdminSettingForm
                                    accountId={user.accountId}
                                    inputChange={inputChange}
                                    inputs={inputs}
                                    loading={loading}
                                    selectChange={selectChange}
                                    selectValue={selectValue}
                                    storeId={user.storeId}
                                    toolbar={[createOrUpdateButton]}
                                    validator={validator}
                                />
                            ),
                        },
                        {
                            label: "Store Users",
                        },
                        {
                            label: "Users",
                            icon: "fas fa-users",
                            render: <StoreUsers />,
                        },

                        ...((storeApps || []).length > 0
                            ? [
                                  {
                                      label: "Actives apps",
                                  },
                              ]
                            : []),

                        ..._.map(storeApps || [], value => {
                            let app = value.app;
                            let appForm;

                            switch (app.handler) {
                                case "paypal":
                                    appForm = (
                                        <AppPayPalForm
                                            app={app}
                                            toolbar={[createOrUpdateButton]}
                                        />
                                    );
                                    break;

                                case "authorizenet":
                                    appForm = (
                                        <AppAuthorizeNetForm
                                            app={app}
                                            toolbar={[createOrUpdateButton]}
                                        />
                                    );
                                    break;

                                case "quickbook":
                                    appForm = (
                                        <AppQuickBookForm
                                            app={app}
                                            toolbar={[createOrUpdateButton]}
                                        />
                                    );
                                    break;
                                case "stripe":
                                    appForm = <StripeForm app={app} />;
                                    break;

                                case "facebook_catalog":
                                    appForm = <FacebookCatalogForm app={app} />;
                                    break;

                                case "metafield":
                                    appForm = <MetafieldForm app={app} />;
                                    break;

                                case "webpay":
                                    appForm = <WebPayForm app={app} />;
                                    break;

                                case "blinddata":
                                    appForm = <BlindDataForm app={app} />;
                                    break;

                                case "measure_request":
                                    appForm = <MeasureRequestForm app={app} />;
                                    break;

                                case "price_table":
                                    appForm = <PriceTableForm app={app} />;
                                    break;

                                case "chile_express":
                                    appForm = <ChileExpressForm app={app} />;
                                    break;

                                case "blaze":
                                    appForm = <BlazeForm app={app} />;
                                    break;

                                case "onFleet":
                                    appForm = (
                                        <OnFleetForm
                                            app={app}
                                            loading={loading}
                                        />
                                    );
                                    break;
                                case "leaflogix":
                                    appForm = <LeafLogixForm app={app} />;
                                    break;

                                case "cova":
                                    appForm = <CovaForm app={app} />;
                                    break;

                                case "treez":
                                    appForm = (
                                        <TreezForm
                                            app={app}
                                            loading={loading}
                                        />
                                    );
                                    break;

                                case "dutchie":
                                    appForm = <DutchieForm app={app} />;
                                    break;

                                case "jane":
                                    appForm = <JaneForm app={app} />;
                                    break;

                                case "berbix":
                                    appForm = <BerbixForm app={app} />;
                                    break;

                                case "idscan":
                                    appForm = <IdScanForm app={app} />;
                                    break;
                                case "redirect":
                                    appForm = <RedirectForm app={app} />;
                                    break;
                                case "reverse_proxy":
                                    appForm = <ReverseProxyForm app={app} />;
                                    break;
                                case "min_zip_by_code":
                                    appForm = <MinZipByCodeForm app={app} />;
                                    break;
                                case "klaviyo":
                                    appForm = <KlaviyoForm app={app} />;
                                    break;
                                case "omnisend":
                                    appForm = <OmnisendForm app={app} />;
                                    break;
                                case "alpineiq":
                                    appForm = (
                                        <AlpineIOForm
                                            app={app}
                                            loading={loading}
                                        />
                                    );
                                    break;
                                case "iterable":
                                    appForm = (
                                        <IterableForm
                                            app={app}
                                            loading={loading}
                                        />
                                    );
                                    break;
                                case "feefo":
                                    appForm = (
                                        <FeefoForm
                                            app={app}
                                            loading={loading}
                                        />
                                    );
                                    break;
                                case "swifter":
                                    appForm = (
                                        <SwifterForm
                                            app={app}
                                            loading={loading}
                                            stores={providers}
                                        />
                                    );
                                    break;
                                case "stronghold":
                                    appForm = (
                                        <StrongholdForm
                                            app={app}
                                            loading={loading}
                                            stores={providers}
                                        />
                                    );
                                    break;
                                case "aeropay":
                                    appForm = (
                                        <AeropayForm
                                            app={app}
                                            loading={loading}
                                            stores={providers}
                                        />
                                    );
                                    break;
                                case "treezpay":
                                    appForm = (
                                        <TreezPayForm
                                            app={app}
                                            loading={loading}
                                            stores={providers}
                                        />
                                    );
                                    break;
                                case "surfside":
                                    appForm = <SurfsideForm app={app} />;
                                    break;
                                case "listrack":
                                    appForm = (
                                        <ListTrackForm
                                            app={app}
                                            stores={treezDispensary}
                                        />
                                    );
                                    break;
                                default:
                                    break;
                            }

                            return {
                                label: app.name,
                                icon: "fas fa-tablet-alt",
                                invalid: false,
                                render: appForm,
                            };
                        }),
                        {
                            label: "Install Apps",
                            permissions: permission.SYSTEMAPPS.read,
                            icon: "fas fa-puzzle-piece",
                            key: "INSTALL",
                            invalid: false,
                            render: (
                                <AppsConfigForm
                                    apps={systemActiveApps}
                                    installApp={activateApp}
                                    loading={loading}
                                    loadingAction={loadingAction}
                                    setLoadingAction={setLoadingAction}
                                    unistallApp={deactivateApp}
                                />
                            ),
                        },
                    ]}
                    setActiveKey={setActiveSection}
                />
            </Content>
        </>
    );
};

export default StoreForm;
