import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Col, Row, FormFeedback, FormGroup, Label, Button } from "reactstrap";
import { useForm, useValidator } from "@/hooks";
import {
    Form,
    Input,
    Section,
    SectionTitle,
    Select,
    Spinner,
} from "@/components";

const TreezPayCredentialForm = ({
    isCreate,
    handleCancel,
    app,
    stores,
    selectCredential,
    setCredentials,
    isUpdating,
    handlerUpdatingOrCreate,
}) => {
    const [isFormReset, resetFormInputs] = useState(isCreate);
    const { inputs, inputChange, setInputs, selectChange, selectValue } =
        useForm();

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "treezpay_store_provider_id",
                method: "isEmpty",
                validWhen: false,
                message: "This Store provider id is required.",
            },
            {
                field: "treezpay_entity_id",
                method: "isEmpty",
                validWhen: false,
                message: "This Entity Id is required.",
            },
            {
                field: "treezpay_username",
                method: "isEmpty",
                validWhen: false,
                message: "This Username is required.",
            },
            {
                field: "treezpay_password",
                method: "isEmpty",
                validWhen: false,
                message: "This Password is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    useEffect(() => {
        if (!isCreate) {
            const objSelect = app?.credentials[selectCredential];
            setInputs({
                treezpay_sandbox: objSelect?.treezpay_sandbox ?? false,
                treezpay_store_provider_id:
                    objSelect?.treezpay_store_provider_id,
                treezpay_entity_id: objSelect?.treezpay_entity_id,
                treezpay_username: objSelect?.treezpay_username,
                treezpay_password: objSelect?.treezpay_password,
            });
        }
    }, []);

    if (!isCreate && !isFormReset) {
        resetFormInputs(true);
    }

    const createOrUpdate = async () => {
        if (isSubmited) {
            isSubmited(true);
        }

        if (isValid()) {
            let accounts = _.cloneDeep(app.credentials ?? []);

            if (isCreate) {
                accounts.push({
                    treezpay_sandbox: inputs?.treezpay_sandbox ?? false,
                    treezpay_store_provider_id:
                        inputs?.treezpay_store_provider_id,
                    treezpay_entity_id: inputs?.treezpay_entity_id,
                    treezpay_username: inputs?.treezpay_username,
                    treezpay_password: inputs?.treezpay_password,
                });
            } else {
                accounts[selectCredential] = inputs;
            }
            setCredentials(accounts);
            handlerUpdatingOrCreate(accounts);
        }
    };

    const handleClose = () => {
        handleCancel();
        resetFormInputs(false);
    };

    return (
        <Form>
            <Section className="p-4 bg-light">
                <Row>
                    <Col>
                        <SectionTitle md>
                            {isCreate ? "Add Credential" : "Update Credential"}
                        </SectionTitle>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <SectionTitle md>Credential Informations</SectionTitle>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col lg={4} md={6}>
                        <FormGroup row>
                            <Label className="row-label mr-3 pl-0">
                                Sandbox
                            </Label>

                            <span className="k-switch k-switch--md k-switch--icon">
                                <label>
                                    <Input
                                        checked={
                                            inputs.treezpay_sandbox
                                                ? "checked"
                                                : ""
                                        }
                                        name="treezpay_sandbox"
                                        onChange={inputChange}
                                        type="checkbox"
                                    />
                                    <span />
                                </label>
                            </span>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Store Provider Id</Label>
                            <Select
                                controlName="treezpay_store_provider_id"
                                invalid={
                                    validator.treezpay_store_provider_id
                                        .isInvalid
                                }
                                onChange={selectChange}
                                options={stores}
                                value={selectValue(
                                    inputs.treezpay_store_provider_id,
                                    stores,
                                )}
                            />
                            <FormFeedback>
                                {validator.treezpay_store_provider_id.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Entity Id</Label>
                            <Input
                                invalid={validator.treezpay_entity_id.isInvalid}
                                name="treezpay_entity_id"
                                onChange={inputChange}
                                placeholder="Entity Id"
                                value={inputs.treezpay_entity_id}
                            />
                            <FormFeedback>
                                {validator.treezpay_entity_id.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Username</Label>
                            <Input
                                invalid={validator.treezpay_username.isInvalid}
                                name="treezpay_username"
                                onChange={inputChange}
                                placeholder="Username"
                                value={inputs.treezpay_username}
                            />
                            <FormFeedback>
                                {validator.treezpay_username.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Password</Label>
                            <Input
                                invalid={validator.treezpay_password.isInvalid}
                                name="treezpay_password"
                                onChange={inputChange}
                                placeholder="Password"
                                type="password"
                                value={inputs.treezpay_password}
                            />
                            <FormFeedback>
                                {validator.treezpay_password.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="justify-content-end pr-3">
                    <Button
                        className="btn__options"
                        color="metal"
                        disabled={isUpdating}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="ml-3"
                        color="primary"
                        onClick={createOrUpdate}
                        style={{ with: "100px !important" }}
                    >
                        {isUpdating && <Spinner color="metal" show sm />}
                        <span>Save</span>
                    </Button>
                </Row>
            </Section>
        </Form>
    );
};

export default TreezPayCredentialForm;
