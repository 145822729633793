import React, { useState } from "react";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import {
    FormGroup,
    Col,
    Label,
    Row,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import { Spinner, Select, Form } from "@/components";
import { useForm, useNotification } from "@/hooks";
import {
    MUTATION_SET_DEFAULT_NOTIFICATION,
    MUTATION_UPDATE_NOTIFICATION_SETTING,
    QUERY_GET_NOTIFICATION_SETTING,
} from "../../apollo/module-operations/notification_setting";
import useMeta from "../../base/hooks/useMeta";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
    notificationSettingOptions,
    notificationSettingTypeOptions,
    VARIABLES_TEMP_BUSSINES_CONTACT,
    VARIABLES_TEMP_DEFAULT,
} from "../../config/constant";
import _ from "lodash";
import { Icon } from "@/components";

const NotificationSettingForm = ({
    toolbar,
    inputs: inputStore,
    inputChange: inputStoreChange,
}) => {
    const { user } = useMeta();
    const notify = useNotification();

    const [isOpen, setIsOpen] = useState(false);

    const { inputs, setInputs, inputChange, selectChange, selectValue } =
        useForm();

    // const [
    //     updateNotificationSetting,
    //     { loading: notificationSettingUpdateLoading },
    // ] = useMutation(MUTATION_UPDATE_NOTIFICATION_SETTING, {
    //     variables: {
    //         account_id: user.accountId,
    //         store_id: user.storeId,
    //         input: {
    //             type: notificationSettingType,
    //             handler: notificationSetting,
    //             content: notificationSettingContent,
    //         },
    //     },

    //     onCompleted: resp => {
    //         setNotificationSetting(resp.updateStoreNotificationSetting.handler);
    //         setNotificationSettingType(
    //             resp.updateStoreNotificationSetting.type,
    //         );
    //     },
    // });

    // const [
    //     setDefaultNotificationSetting,
    //     { loading: notificationDefaultSettingUpdateLoading },
    // ] = useMutation(MUTATION_SET_DEFAULT_NOTIFICATION, {
    //     variables: {
    //         account_id: user.accountId,
    //         store_id: user.storeId,
    //     },

    //     onCompleted: resp => {
    //         const notificationSettings = resp.setDefaultNotificationSettings;
    //         const nSetting = _.find(notificationSettings, {
    //             handler: notificationSetting,
    //         });
    //         setNotificationSettingContent(nSetting.content);
    //     },
    // });

    const variables = { account_id: user.accountId, store_id: user.storeId };
    const handler = inputs.handler || notificationSettingOptions[0].value;

    const { loading: getLoading } = useQuery(QUERY_GET_NOTIFICATION_SETTING, {
        variables: { ...variables, handler },
        skip: user.loading,
        fetchPolicy: "network-only",
        onCompleted: resp => {
            const {
                content,
                handler,
                type,
                notification_active,
                email_subject,
            } = resp.getStoreNotificationSetting;
            setInputs({
                content,
                handler,
                type,
                notification_active,
                email_subject: email_subject ?? "",
            });
        },
        onError: () => {
            notify("Error getting selected notification settings", "error");
            setInputs({ ...inputs, content: "" });
        },
    });

    const [update, { loading: updateLoading }] = useMutation(
        MUTATION_UPDATE_NOTIFICATION_SETTING,
        {
            variables: {
                ...variables,
                input: {
                    type: inputs.type,
                    handler,
                    content: inputs.content,
                    email_subject: inputs.email_subject,
                    notification_active: inputs.notification_active,
                },
            },
            onCompleted: () => {
                notify("Notification settings updated successfully", "success");
            },
            onError: () => {
                notify("Update notification setting", "error");
            },
        },
    );

    const [setDefault, { loading: defaultLoading }] = useMutation(
        MUTATION_SET_DEFAULT_NOTIFICATION,
        {
            variables,
            onCompleted: resp => {
                const { content, type, handler } = _.find(
                    resp.setDefaultNotificationSettings,
                    setting =>
                        setting.handler === inputs.handler &&
                        setting.type === inputs.type,
                );

                setInputs({ content, type, handler });
            },
        },
    );

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    const clipboardCopy = text => {
        navigator.clipboard.writeText(text);
        notify(`${text} is copied`, "success");
    };

    const getListVariables = () => {
        if (inputs.handler === "contact/business_owner") {
            return VARIABLES_TEMP_BUSSINES_CONTACT;
        }

        return VARIABLES_TEMP_DEFAULT;
    };

    const isLoading = updateLoading || getLoading || defaultLoading;

    return (
        <Portlet
            className="business__settings"
            header={{ title: "Notification settings" }}
            sticky
            toolbar={
                <>
                    {toolbar}
                    <Button
                        className="ml-3"
                        color="secondary"
                        disabled={isLoading}
                        onClick={() =>
                            update({
                                variables: {
                                    ...variables,
                                    input: {
                                        type: inputs.type,
                                        handler,
                                        content: inputs.content,
                                        notification_active:
                                            inputs.notification_active,
                                        email_subject: inputs.email_subject,
                                    },
                                },
                            })
                        }
                    >
                        <i className="fas fa-envelope" />
                        Update Template
                    </Button>
                    <Button
                        className="ml-3"
                        color="metal"
                        disabled={isLoading}
                        onClick={setDefault}
                    >
                        <i className="fas fa-sync-alt" />
                        Set Defaults
                    </Button>
                </>
            }
        >
            <Form>
                <Section>
                    <Row>
                        <Col>
                            <SectionTitle md>Basic Settings</SectionTitle>
                        </Col>
                    </Row>
                    <FormGroup row>
                        <Label className="row-label">Active</Label>
                        <Col>
                            <span className="k-switch k-switch--md k-switch--icon">
                                <label>
                                    <Input
                                        checked={
                                            inputStore.email_notification_active
                                                ? "checked"
                                                : ""
                                        }
                                        name="email_notification_active"
                                        onChange={inputStoreChange}
                                        type="checkbox"
                                    />
                                    <span />
                                </label>
                            </span>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col lg={4}>
                            <Label>Notification Type</Label>
                            <Select
                                controlName="type"
                                onChange={selectChange}
                                options={notificationSettingTypeOptions}
                                value={selectValue(
                                    inputs.type,
                                    notificationSettingTypeOptions,
                                )}
                            />
                        </Col>
                        <Col className="ml-2" lg={4}>
                            <Label>From Email Address</Label>
                            <Input
                                name="from_tx_email"
                                onChange={inputStoreChange}
                                value={inputStore.from_tx_email}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col lg={4}>
                            <Label>Email Type</Label>
                            <Select
                                controlName="handler"
                                onChange={selectChange}
                                options={notificationSettingOptions}
                                value={selectValue(
                                    inputs.handler,
                                    notificationSettingOptions,
                                )}
                            />
                        </Col>
                        <Col className="ml-2" lg={4}>
                            <Label>If On, Email type will be active</Label>
                            <span
                                className="k-switch k-switch--md k-switch--icon"
                                style={{ display: "block" }}
                            >
                                <label>
                                    <Input
                                        checked={
                                            inputs.notification_active
                                                ? "checked"
                                                : ""
                                        }
                                        name="notification_active"
                                        onChange={inputChange}
                                        type="checkbox"
                                    />
                                    <span />
                                </label>
                            </span>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col lg={8}>
                            <Label>Email Subject</Label>
                            <Input
                                name="email_subject"
                                onChange={inputChange}
                                value={inputs.email_subject}
                            />
                        </Col>
                    </FormGroup>

                    {isLoading && (
                        <FormGroup row>
                            <Col>
                                <Spinner color="metal" show sm />
                            </Col>
                        </FormGroup>
                    )}
                    {!isLoading && (
                        <>
                            <FormGroup row>
                                <Col className="notification__content">
                                    <Label>Content</Label>
                                    <Input
                                        name="content"
                                        onChange={inputChange}
                                        placeholder="Write the template..."
                                        rows={20}
                                        type="textarea"
                                        value={
                                            inputs.content !== ""
                                                ? inputs.content
                                                : ""
                                        }
                                    />
                                    <Button
                                        className="btn__variables_template"
                                        color="metal"
                                        disabled={isOpen}
                                        onClick={toggleModal}
                                    >
                                        View Variables
                                    </Button>
                                </Col>
                                <Modal
                                    isOpen={isOpen}
                                    size="lg"
                                    style={{ marginTop: "120px" }}
                                    toggle={toggleModal}
                                >
                                    <ModalHeader toggle={toggleModal}>
                                        Available variables
                                    </ModalHeader>
                                    <ModalBody className="notification__tp_body">
                                        {getListVariables().map(
                                            (item, index) => (
                                                <div key={index}>
                                                    <h5>{item.label}</h5>
                                                    <ul>
                                                        {item.value.map(
                                                            (
                                                                subItem,
                                                                subIndex,
                                                            ) => (
                                                                <li
                                                                    className="notification__variable"
                                                                    key={
                                                                        subIndex
                                                                    }
                                                                >
                                                                    <span>
                                                                        {
                                                                            subItem
                                                                        }
                                                                    </span>
                                                                    <Button
                                                                        className="notification__copy"
                                                                        onClick={() =>
                                                                            clipboardCopy(
                                                                                subItem,
                                                                            )
                                                                        }
                                                                        title="Copy POS product name"
                                                                    >
                                                                        <Icon
                                                                            color="#727a94"
                                                                            icon="copy"
                                                                        />
                                                                    </Button>
                                                                </li>
                                                            ),
                                                        )}
                                                    </ul>
                                                </div>
                                            ),
                                        )}
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button
                                            color="secondary"
                                            onClick={toggleModal}
                                            style={{
                                                width: "140px",
                                                marginRight: "8px",
                                            }}
                                        >
                                            Close
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                            </FormGroup>
                            <FormGroup row>
                                <Col>
                                    <Label>Preview</Label>
                                    <iframe
                                        srcDoc={
                                            inputs.content !== ""
                                                ? inputs.content
                                                : `<style>
                                                    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
                                                    p {
                                                        color: #9CA1B4;
                                                        font-size: 14px;
                                                        line-height: 1.5;
                                                        padding-left: 14px;
                                                        font-family: 'Poppins', sans-serif;
                                                    }
                                                </style> 
                                                <p>
                                                  There is no template available to view...
                                                </p>`
                                        }
                                        style={{
                                            width: "100%",
                                            height: "1200px",
                                            border: "1px solid #d5d9e5",
                                            borderRadius: "0.25rem",
                                        }}
                                        title="Email Preview"
                                    />
                                </Col>
                            </FormGroup>
                        </>
                    )}
                </Section>
            </Form>
        </Portlet>
    );
};

export default NotificationSettingForm;
